.dropdown-helper {
  position: relative;
  overflow: hidden;

  [data-toggle="dropdown"] {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.padding {
      padding: 5px 0;
    }

    @include rwd(tablet) {
      padding: 0;
    }

    [data-toggle-text] {
      position: relative;
    }

    i + [data-toggle-text] {
      margin-left: rem-calc(5);
    }

    #{$icon-selector} {
      font-size: 15px;
    }

    .counter {
      position: absolute;
      right: -7px;
      top: -7px;
      z-index: 2;
      border-radius: 50%;
      background-color: $primary;
      color: $white;
      width: 16px;
      height: 16px;
      font-size: rem-calc(10);
      line-height: 16px;
      text-align: center;
    }

    &[data-style="arrow"] {
      padding-right: rem-calc(25);

      &:before {
        @include font-icon('\F101');
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        font-size: rem-calc(12);
        //transition: .05s linear !important;
        transform-origin: 6px 4px;
        margin-top: 1px;
      }
    }
  }

  .dropdown-content {
    position: absolute;
    top: 100%;
    background-color: $theme-bg;
    width: 250px;
    border-radius: 5px;
    z-index: 100;
    box-shadow: 0 0 15px rgba(0, 0, 0, .1);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    //transform: scaleY(0);
    font-weight: 400;

    &__head {
      @include font(14, 18, 0);
      color: $black;
      border-bottom: 1px solid $gray-200;
      font-weight: 500;
      padding: 10px;

      &--mobile {
        @include rwd(tablet, true) {
          display: none !important;
        }
      }
    }

    &--helper {
      padding: rem-calc(10) rem-calc(20);
      background-color: #fff;
      border-radius: 5px;
      overflow: hidden;

      @include rwd(tablet) {
        padding: 5px 0;
      }

      &-max-height {
        max-height: 250px;
        overflow: auto;
      }
    }

    &-right {
      transform-origin: top right;
      right: 0;
    }

    &-left {
      transform-origin: top left;
      left: 0;
    }

    &__link {
      @include font(14, 16, 0);
      display: block;
      padding: rem-calc(9) 0;

      &--flex {
        display: flex;
        align-items: center;

        #{$icon-selector} {
          margin-right: 8px;
        }
      }

      @include rwd(tablet) {
        padding: 10px;
      }

      a {
        @include font(14, 16, 0);
        display: block;
        padding: rem-calc(9) 0;
      }

      &--disabled {
        pointer-events: none;
        opacity: .7;
      }
    }

    &[data-animation="top"] {
      //transform-origin: top;
      //visibility: visible;
      //pointer-events: auto;
      //transform: scaleY(0);
    }

    &[data-animation="fade"] {
      //transform-origin: top;
      //transform: scaleY(1);
    }
  }

  &.is-open {
    overflow: visible !important;

    .dropdown-content {
      //transform: scaleY(1);
      opacity: 1 !important;
      visibility: visible;
      pointer-events: auto;
      max-height: 80vh;
      @include rwd(tablet) {
        display: block;
      }
    }

    [data-style="arrow"] {
      &:before {
        transform: rotateZ(180deg);
      }
    }
  }

  &--links {
    a[data-toggle], [data-toggle] {
      color: $gray-500;
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;

      &[data-style="arrow"] {
        padding-right: 30px;
      }

      .title {
        display: block;
        @include font(11, 11, 0);
        color: $gray-500;
        font-weight: 400;
      }

      .name {
        font-weight: 600;
        color: $text;
      }

      @include on-event() {
        background: $gray-100;
      }
    }

    .dropdown-content {
      &--helper {
        padding: 0;
      }

      a {
        padding: rem-calc(10) rem-calc(15);
        border-bottom: 1px solid $theme-bg;
        @include font(14, 16, 0);
        display: flex;
        align-items: center;
        @include on-event() {
          background-color: $gray-100;
        }

        &.highlighted {
          background-color: $primary-light;
          color: #fff;
          border: none;
          @include on-event() {
            background-color: $primary;
          }
        }

        &.active {
          background-color: $gray-100;
          color: $primary-dark;
          @include on-event() {
            background-color: $gray-100;
          }
        }
      }
    }
  }
}
