$inputFontWeight: 400;
$inputFontSize: 13px;
$inputFontColor: $theme-dark;
$inputPlaceholderColor: $theme-gray;
$inputPaddingLeft: 16px;
$inputPaddingRight: 16px;
$inputPaddingLeftWithIcon: 35px;
$inputPaddingRightWithIcon: 35px;

// props
$labelAsPlaceholder: true;

.field {
  $block: &;
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 100%;
  line-height: 1;
  font-family: $font-family-redesign;

  input, select, textarea {
    padding-left: $inputPaddingLeft;
    padding-right: $inputPaddingRight;
    width: 100%;
    outline: none;
    -webkit-appearance: none;
    margin: 0;
    background-color: #fff;
    border: 1px solid rgba($theme-gray-lighter, .5);
    border-radius: 5px;
    font-size: $inputFontSize;
    color: $inputFontColor;
    font-weight: $inputFontWeight;
    height: 40px;
    @include transition(border-color);

    @include rwd(tablet) {
      height: 44px;
    }

    @include placeholder {
      font-size: $inputFontSize;
      color: $inputPlaceholderColor;
      font-weight: $inputFontWeight;
    }

    &:focus {
      border-color: $theme;
    }
  }

  textarea {
    resize: none;
    padding-top: 11px;
    padding-bottom: 11px;
    min-height: 40px;
    max-height: 100px;

    @include rwd(tablet) {
      padding-top: 13px;
      padding-bottom: 13px;
      min-height: 44px;
    }
  }

  // types
  &--select {
    @extend .field--icon;

    select {
      @include reset(select);
      min-width: 100px;
      padding-left: $inputPaddingLeft !important;
      padding-right: $inputPaddingRightWithIcon !important;
    }

    i, svg, &:before {
      left: auto !important;
      right: 3px !important;
      font-size: 24px !important;
    }

    &:before {
      @extend .fal;
      content: "\f107";
      width: 1.25em;
      text-align: center;
      color: $theme-light !important;
    }

    &--opened {
      &:before {
        content: "\f00d" !important;
        color: $danger !important;
        font-size: 20px !important;
      }
    }
  }

  &--select-special {
    select {
      background-color: $theme-bg;
      color: $theme-dark;
      font-weight: 700;
      height: 32px !important;
      border: none;
    }

    i, svg, &:before {
      color: $theme-gray-lighter !important;
      top: auto !important;
      bottom: 15px !important;
      transform: translateY(50%) !important;
      left: auto;
    }

    label {
      @extend %text-small;
      font-weight: 700;
      color: $theme;
      margin-bottom: 4px;
    }
  }

  @at-root .searchable-container {
    @at-root select {
      &[searchable], &[multiple] {
        @extend .visually-hidden;
      }
    }

    border: 1px solid rgba($theme-gray-lighter, .5);
    border-radius: 5px;
    position: relative;
    background-color: #fff;

    .input-helper ~ & {
      margin-top: 5px;
      margin-bottom: 20px;
    }

    .selected-items {
      border-bottom: none;
      padding: 5px $inputPaddingRight 0 $inputPaddingLeft;

      &__list {
        @include flex(flex-start, center, true);
        margin-left: -3px;
        margin-right: -3px;
      }
    }

    .selected-item {
      @include reset(button);
      margin: 3px;
      cursor: pointer;
      @include transition(opacity);

      &:hover {
        opacity: .7;
      }

      .lab {
        @include flex(center, center, false, false, true);

        &:after {
          @extend .fal;
          content: "\f00d";
          flex-shrink: 0;
          margin-left: 3px;
          color: $danger;
          font-size: 10px;
        }
      }
    }

    .selectable-items {
      position: absolute;
      left: -1px;
      right: -1px;
      top: 100%;
      margin-top: -4px;
      z-index: 10;
      background-color: #fff;
      padding: 2px;
      max-height: 200px;
      overflow: auto;
      border: 1px solid rgba($theme-gray-lighter, .5);
      border-top: none;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #f7faff;
      }

      &::-webkit-scrollbar {
        width: 8px;
        background-color: #f7faff;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, .1);
        background-color: $primary;
      }

      &-top-edge {
        border-top: 1px solid rgba($theme-gray-lighter, .5);
        border-radius: 5px 5px 0 0;
        border-bottom: none;
      }
    }

    .selectable-item {
      @include reset(button);
      border-radius: 4px;
      width: 100%;
      display: block;
      text-align: left;
      padding: 9px 15px;
      @include transition(background-color);
      cursor: pointer;

      &:hover {
        background-color: $theme-bg;
      }
    }
  }

  &--searchable-select {
    input {
      padding-left: $inputPaddingLeft !important;
      padding-right: $inputPaddingRightWithIcon !important;
      //margin-left: #{$inputPaddingLeft * -1};
      //margin-right: #{$inputPaddingLeft * -1};
      border: none;
    }
  }

  &--checkbox {
    margin-top: 0;

    label {
      @extend %text-base;
      color: $theme-dark;
      cursor: pointer;
      position: relative;
      margin: 0;
      padding-left: 26px;
      display: block;
      min-height: 18px;
      padding-top: 1px;

      a {
        @extend %text-base;
        color: $theme;
        @include link-hover($theme-hover);
      }

      &:before {
        content: '';
        width: 18px;
        height: 18px;
        display: block;
        border: 1px solid darken(#EAEDF0, 10%);
        position: absolute;
        left: 0;
        top: 1px;
        border-radius: 4px;
        @include transition(border-color .1s);
      }

      &:after {
        content: '';
        display: block;
        width: 5px;
        height: 10px;
        border-style: solid;
        border-color: $theme;
        border-width: 0 2px 2px 0;
        opacity: 0;
        transform: rotate(45deg);
        position: absolute;
        left: 7px;
        top: 4px;
        @include transition(opacity .1s);
      }

      @include on-event() {
        &:after {
          opacity: .3;
        }
      }
    }

    input {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + label {
        &:before {
          border-color: #b9cfff;
        }

        &:after {
          opacity: 1;
        }
      }
    }

    @at-root #{selector-unify(&, #{$block}--large)} {
      label {
        padding: 0;
        margin: 0;
        min-width: 20px;
        min-height: 20px;

        &:before {
          width: 20px;
          height: 20px;
          border: 2px solid #cccccc;
        }

        &:after {
          width: 5px;
          height: 12px;
          left: 8px;
          border-color: $theme;
        }
      }
    }
  }

  &--visual-choice {
    width: 32px;
    height: 32px;
    padding: 0 !important;
    margin-left: 2px;
    margin-right: 2px;
    margin-top: 6px;

    label {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px solid $theme-secondary-ultralight;
      cursor: pointer;
      color: $theme-gray;
      margin: 0;
      background-color: $white;
      @include transitions(background-color, color, border-color);

      @include on-event() {
        background-color: rgba($theme, .1);
      }

      #{$icon-selector} {
        pointer-events: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 19px;
      }
    }

    input {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + label {
        border-color: $theme;
        background-color: $theme;
        color: $white;
      }
    }

    &-color {
      label {
        border-color: transparent !important;

        #{$icon-selector} {
          color: $white;
          opacity: 0;
          @include transition(opacity);
        }
      }

      input {
        &:checked + label {
          #{$icon-selector} {
            opacity: 1;
          }
        }
      }
    }
  }

  &--label-as-placeholder {
    &:not(#{$block}--checkbox):not(#{$block}--select-special):not(#{$block}--visual-choice) {
      label {
        display: block;
        position: absolute;
        top: 20px;
        left: $inputPaddingLeft;
        transform: translateY(-50%);
        z-index: 2;
        padding: 0 2px;
        background: #fff;
        font-size: $inputFontSize;
        color: $inputPlaceholderColor;
        font-weight: $inputFontWeight;
        margin: 0;
        @include transitions(left, top, font-size);
        pointer-events: none;
        -webkit-backface-visibility: hidden;

        @include rwd(tablet) {
          top: 22px;
        }
      }

      select {
        & + label {
          top: 0;
          left: $inputPaddingLeft !important;
          color: $inputPlaceholderColor;
          font-size: 11px;
        }
      }

      @at-root #{selector-unify(&, #{$block}--icon)} {
        label {
          left: $inputPaddingLeftWithIcon;
        }
      }

      @at-root #{selector-unify(&, #{$block}--label-is-active)} {
        label {
          left: $inputPaddingLeft !important;
          top: 0;
          color: $theme;
          font-size: 11px;
        }
      }
    }
  }

  &--error {
    input, select, textarea {
      border-color: rgba(255, 58, 50, 0.4);
    }
  }

  // styles
  &--white {
    input, select, textarea {
      border: 1px solid #fff !important;
    }
  }

  &--no-border {
    input, select, textarea {
      border: none !important;
    }
  }

  &--icon {
    position: relative;

    svg, i, &:before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 13px;
      z-index: 1;
      color: $theme-gray;
      pointer-events: none;
    }

    svg {
      width: 15px !important;
      height: 15px !important;
    }

    i {
      font-size: 11px;
    }

    input, select, textarea {
      padding-left: $inputPaddingLeftWithIcon;
    }
  }

  // sizes
  &--size {
    &-4 {
      width: 98%;
    }
    &-3 {
      width: 73%;
      @include rwd(large) {
        width: 98%;
      }
    }
    &-2 {
      width: 48%;
      @include rwd(tablet) {
        width: 98%;
      }
    }
    &-1 {
      width: 23%;
      @include rwd(large) {
        width: 48%;
      }
      @include rwd(medium) {
        width: 98%;
      }
    }
  }

  // default styles
  @if $labelAsPlaceholder {
    @extend #{$block}--label-as-placeholder;
  }

  &__info {
    @extend %text-base;
    color: $secondary;
    font-weight: 400;
    margin-top: 2px;
    text-align: left;

    a {
      @extend %text-base;
      color: $theme;
      font-weight: 300;
      @include transition(color);
      @include on-event() {
        color: $theme-hover;
      }
    }
  }

  &__error {
    font-size: 11px;
    color: $danger;
    position: absolute;
    right: 3px;
    bottom: 3px;
  }
}

.searchable-container {
  &-top-edge {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 10;
  }
}
