.dishes-select {
  display: flex;
  flex-wrap: wrap;
  margin: -5px -5px 20px -5px;

  &__item {
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin: 5px;

    .input-helper {
      margin: 0;
      padding: 4px 10px;

    }
  }

  &__half {
    background: #eee;
    padding: 0 2px;

    .input-helper {
      .checkbox {
        label {
          background: transparent;
          span {
            color: #999;
          }
        }
      }
    }
  }
}
