.high-contrast {
  *:not(#hs-web-interactives-top-anchor){
    background: #000 !important;
    color: #fff !important;
  }

  .high-contrast__hide {
    display: none !important;
  }
}

a, b, blockquote, button, canvas, caption,
center, cite, code, col, colgroup, dd,
details, dfn, dir, div, dl, dt, em, embed,
fieldset, figcaption, figure, font, footer, form,
header, i, iframe, img, input, kbd, label, legend,
li, mark, menu, meter, nav, nobr, object, ol,
option, pre, progress, q, s, section, select,
small, span, strike, strong, sub, summary, sup,
table, td, textarea, th, time, tr, tt, u, ul,
var, a span, strong, input::placeholder,
{
  .high-contrast & {
    color: yellow !important;
  }
}

button, input, textarea, select, table,
td, th, tr, tt, .page-box, .lab,
.av__layout, .event-box, .toast,
.timeline__item, .month-summary__item,
.dropdown-content, .search-engine__results,
.avatar__image, .badge, .fancybox-button,
.page-component, .popup__content, .ql-picker-label,
.row, .dashboard-summary__content
{
  .high-contrast & {
    box-shadow: none !important;
    border: 1px solid #fff !important;
  }
}

a.active {
  .high-contrast & {
    color: #000 !important;
  }
}

.av--redesign-child {
  .high-contrast & {
    .av__layout {
      .avatar__initials {
        color: yellow !important;
      }
    }
  }
}

.calendar-legend__item {
  .high-contrast & {
    &:after {
      border: 1px solid #fff !important;
      background: transparent !important;
    }

    span {
      background: transparent !important;
    }
  }
}

.fc-toolbar {
  .high-contrast & {
    .fc-left button, .fc-right button {
      border: none !important;
      padding-bottom: 7px !important;
      background: yellow !important;
    }

    .fc-left button {
      padding-right: 1px !important;
    }

    .fc-right button {
      padding-left: 1px !important;
    }

    .fc-prev-button span, .fc-next-button span {
      background: yellow !important;

      &:before {
        color: #000 !important;
      }
    }
  }
}

.button, .btn, .uploader-item__media {
  .high-contrast & {
    background: yellow !important;
    color: #000 !important;
    border: none !important;

    * {
      background: yellow !important;
      color: #000 !important;
    }
  }
}

.active:not(div) {
  .high-contrast & {
    background: yellow !important;

    @include rwd(tablet, true) {
      border-top-right-radius: 40px !important;
      border-bottom-right-radius: 40px !important;
    }

    &:before {
      content: '' !important;
      position: absolute !important;
      top: 0 !important;
      bottom: 0 !important;
      left: -16px !important;
      width: 16px !important;
      display: block !important;
      background-color: yellow !important;
    }

    .side-nav__icon {
      background: yellow !important;

      #{$icon-selector} {
        background: yellow !important;

        &:before {
          color: #000 !important;
        }
      }
    }

    .mobile-dock__item-layout {
      background: yellow !important;

      * {
        background: yellow !important;
        color: #000 !important;
      }
    }
  }
}

.side-nav__submenu {
  .high-contrast & {
    &:before {
      background-color: rgba(255, 255, 0, 0) !important;
    }
  }
}

.input-helper input[type=text], .field--textarea textarea,
.input-helper select, .input-helper input[type=email],
.input-helper input[type=password], .input-helper textarea  {
  .high-contrast & {
    color: #fff !important;
  }
}

.post__comments-add-btn {
  .high-contrast & {
    border: none !important;
    background: transparent !important;
  }
}

.app-brand {
  .high-contrast & {
    a {
      filter: grayscale(1) brightness(100);
    }
  }
}

.info-box, .msg, .message {
  .high-contrast & {
    border-top: 1px solid #fff !important;
    border-right: 1px solid #fff !important;
    border-bottom: 1px solid #fff !important;
  }
}

.special-checkboxes {
  .high-contrast & {
    .input-helper {
      label {
        color: #fff !important;
      }
    }
  }
}

.uploader-item__content {
  .high-contrast & {
    border: 1px solid #fff !important;

    &__remove {
      &:before {
        background: transparent !important;
      }
    }
  }
}

.tabs-container {
  .high-contrast & {
    &:before {
      background: transparent !important;
    }

    .button--tab {
      padding: 5px !important;
      margin-top: 7px !important;
      margin-bottom: 7px !important;

      &-active {
        &:before {
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important;
          height: 3px !important;
          bottom: 0 !important;
        }

        @include rwd(phone) {
          border: 2px solid $theme !important;
        }
      }
    }
  }
}

.inline-calendar__day--current {
  .high-contrast & {
    .inline-calendar__day-bottom {
      span {
        background: yellow !important;
        color: #000 !important;
      }

      &:before {
        background: yellow !important;
      }
    }
  }
}

.button--icon-text, .button--icon-with-text, .element--with-icon {
  .high-contrast & {
    padding: 2px 4px 2px 4px !important;
    border-radius: 4px !important;
  }
}

.presence-list-table__presence {
  .high-contrast & {
    border-radius: 10px !important;
    * {
      border-radius: 10px !important;
    }
  }
}

.single-file-uploader__select-new {
  .high-contrast & {
    label {
      &:before {
        color: #000 !important;
      }
    }
  }
}

.element--with-icon {
  .high-contrast & {
    background: yellow !important;
    * {
      background: yellow !important;
      color: #000 !important;
    }
  }
}
