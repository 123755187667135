.sidebar {
  $block: &;
  min-height: 100vh;
  box-shadow: 0 0 20px rgba(229,233,235, 0.8);
  font-family: $font-family-redesign;

  @include rwd(tablet) {
    box-shadow: none;
  }

  &__layout {
    @include flex(flex-start, stretch, false, false, false);
    min-height: 100vh;
    position: sticky;
    top: 0;

    @include rwd(tablet) {
      display: none;
    }

    &--mobile {
      display: none;

      @include rwd(tablet) {
        @include flex(flex-start, flex-start, false, true);
        display: block;
        height: 100vh;
        width: $sidebar-mobile-width;
        background: $white;
        position: fixed;
        z-index: 12;
        right: -#{$sidebar-mobile-width};
        @include transition(right);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        padding: 16px;
        overflow: auto;

        .app-brand {
          padding-bottom: 0 !important;
        }
      }

      .sidebar--active & {
        @include rwd(tablet) {
          right: 0;
        }
      }
    }
  }

  &__top {
    @include flex(space-between, center, false, false);
    padding-bottom: 25px;
  }

  &__current-user {
    padding: 0 3px 20px 3px;
  }
  &__actions {
    #{$icon-selector} {
      font-size: 15px;
    }

    .button {
      padding: 10px 3px;

      &__text {
        color: $text;
      }
    }
  }

  &__left {
    min-height: 100vh;
    max-height: 100vh;
    width: $sidebar-left-width;
    background: $redesign-nav-bg;
    padding: 0;
    position: relative;
  }

  &__right {
    min-height: 100vh;
    width: $sidebar-right-width;
    background: $white;
    padding: 20px 15px;
    max-height: 100vh;
    overflow-y: auto;

    .branches-list {
      .field {
        label {
          margin-top: 9px;
        }
      }
    }

    &--gap {
      min-height: 22px;
    }
  }

  .branches-list {
    .field {
      margin-bottom: 20px;
    }
  }

  .side-nav {
    position: relative;
    &--bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 5px;
      background: $redesign-nav-bg;
      z-index: 1;
    }

    &__item {
      @include flex(center, center, false, true);
      height: 55px;
      width: 100%;
      margin: 10px 0;

      #{$icon-selector} {
        font-size: 19px;
        margin-bottom: 5px;
      }

      &--active {
        position: relative;
        background: $white;

        #{$icon-selector} {
          color: $theme;
        }

        .corner-top-left, .corner-top-right, .corner-bottom-left, .corner-bottom-right {
          width: 10px;
          height: 10px;
          overflow: hidden;
          position: absolute;

          &:before {
            content: '';
            display: block;
            width: 200%;
            height: 200%;
            position: absolute;
            border-radius: 50%;
          }
        }
        .corner-top-left {
          top: 0;
          left: 5px;

          &:before {
            top: 0;
            left: 0;
            box-shadow: -5px -5px 0 0 $redesign-nav-bg;
          }
        }
        .corner-top-right {
          top: -10px;
          right: 0;

          &:before {
            bottom: 0;
            right: 0;
            box-shadow: 5px 5px 0 0 #fff;
          }
        }
        .corner-bottom-left {
          left: 5px;
          bottom: 0;

          &:before {
            bottom: 0;
            left: 0;
            box-shadow: -5px 5px 0 0 $redesign-nav-bg;
          }
        }
        .corner-bottom-right {
          right: 0;
          bottom: -10px;

          &:before {
            top: 0;
            right: 0;
            box-shadow: 5px -5px 0 0 #fff;
          }
        }
      }
    }
  }

  .side-nav-list {
    display: none;

    &--active {
      display: block;
    }

    &__item {
      display: block;
      padding: 8px 0;

      #{$icon-selector} {
        margin-right: 5px;
      }
    }

    &__title {
      color: $primary;
      text-transform: uppercase;
      margin-top: 12px;
      margin-bottom: 6px;
      font-size: 11px;
      font-weight: 500;
    }
  }

  .side-nav-list__item, .side-nav__item {
    @include transition(color);
    color: $theme-dark;

    @include on-event() {
      color: $theme-hover;
    }

    &--return {
      color: $gray-600;
      margin-top: 20px;
      @include on-event() {
        color: $theme-hover;
      }
    }
  }

  .parent-menu-title {
    @include font(14, 17);
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .app-brand {
    width: 100%;
    @include flex(center, center);
    padding: 0 0 40px 0;

    &.with-mac{
      padding-bottom: 22px;
    }

    .linkmac{
      margin-top: 1.25rem;
      @include rwd(tablet) {
        margin-top: -0.125rem;
        margin-left: 1.25rem;
      }
    }

    .logo {
      height: 20px;
      &.mac{
        height: 33px;
      }

      &--custom {
        height: unset;
        max-height: 180px !important;
        object-fit: contain;
        width: 100%;
      }
    }
  }

  &__notifications {
    width: 100%;
    border-top: 1px solid #EAEDF0;
    margin-top: 16px;
    padding-top: 13px;
    padding-bottom: 50px;
  }

  .trial-info {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 10px;
    background: lighten($warning, 36);
    color: $warning;

    &__title {
      @extend %text-base;
      font-weight: 700;
      margin-bottom: 2px;
    }

    &__desc {
      @include font(11, 12, 0);
    }

    &--expired {
      background-color: lighten($danger, 34);
      color: $danger;
    }
  }

  &__intercom {
    @include flex(flex-start, center, false, false, false);
    border-top: 1px solid #EAEDF0;
    margin-top: 7px;
    padding-top: 16px;
  }
}
