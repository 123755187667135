[data-range-picker-helper] {
  padding-top: 0 !important;
}

[data-range-picker] {
  @extend .visually-hidden;

  & ~ .litepicker {
    display: block;
    width: 100%;
    font-size: 1.1em;
    padding-top: 0;

    .container {
      &__tooltip {
        z-index: 2;
      }

      &__days {
        position: relative;
        z-index: 1;
      }

      &__months {
        box-shadow: none;

        @include rwd(small) {
          margin-left: auto;
          margin-right: auto;
        }

        &.columns-2 {
          @include flex(flex-start, stretch, false);
          flex-wrap: nowrap;
        }
      }
    }

    .month-item {
      @include rwd(small) {
        padding-top: 0;
      }
    }

    .month-item-header {
      @include rwd(small) {
        padding-top: 0;
      }
    }

    .day-item {
      overflow: hidden;
      padding-top: 10px;
      padding-bottom: 10px;
      position: relative;
      border-radius: 50%;

      &.is-highlighted, &.is-booked {
        &:before {
          content: '';
          display: block;
          position: absolute;
          z-index: -1;
          pointer-events: none;
          left: 1px;
          top: 1px;
          right: 1px;
          bottom: 1px;
          border-radius: 50%;
        }
      }

      &.is-highlighted {
        background-color: transparent;

        &:before {
          background-color: var(--litepickerHighlightedDayBg);
        }
      }

      &.is-booked {
        border-radius: 50%;

        &:before {
          left: 0;
          top: 0;
          right: 0;
          bottom: auto;
          height: 50%;
          width: var(--litepickerDayWidth);
          border-radius: 50% 50% 0 0;
          transform-origin: bottom;
          transform: rotate(-45deg);
          background-color: var(--litepickerDayIsBookedBg);
        }
      }

      &.is-start-date {
        border-radius: 50% 0 0 50%;

        &.is-flipped {
          border-radius: 0 50% 50% 0;
        }
      }

      &.is-end-date {
        border-radius: 0 50% 50% 0;

        &.is-flipped {
          border-radius: 50% 0 0 50%;
        }
      }

      &.is-start-date, &.is-end-date {
        &.is-highlighted {
          background-color: var(--litepickerDayIsStartBg);

          &:before {
            display: none;
          }
        }
      }

      &.is-start-date.is-end-date {
        border-radius: 50%;
      }

      &.is-in-range {
        background-color: var(--litepickerDayIsInRange);
        border-radius: 0;
      }
    }
  }
}

.litepicker-legend {
  padding: 2px 0 0 14px !important;
  margin: 0 !important;

  &__item {
    position: relative;
    @include flex(flex-start, center, false);
    color: $theme-gray;

    &:before {
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      pointer-events: none;
      flex-shrink: 0;
      margin-right: 5px;
    }

    &--highlighted {
      &:before {
        background-color: var(--litepickerHighlightedDayBg);
      }
    }
  }
}
