.badge {
  font-size: .8em;
  padding: 3px 6px;
  color: #fff;
  display: inline-block;
  border-radius: 3px;
  margin: 0 5px;
  font-weight: bold;

  &--primary {
    background-color: $primary;
  }

  &--success {
    background-color: $success;
  }

  &--warning {
    background-color: $warning;
  }

  &--danger {
    background-color: $danger;
  }

  &--danger-redesign {
    border-radius: 40%;
    padding: 3px 9px;
    background-color: rgba($danger, 0.1);
    color: $danger;
    font-weight: 400;
  }

  &--white {
    background-color: #fff;
    color: $text;
  }

  &--neutral {
    background-color: $gray-600;
  }

  &--neutral-light {
    background-color: $gray-200;
    color: $text;
  }

  // sizes
  &--medium {
    font-size: .9rem;
    padding: 5px 10px;
  }
  &--large {}

  // fonts
  &--semibold {
    font-weight: 600;
  }

  &--rounded {
    border-radius: 4px;
  }
}
