.notifications {
  &__content {
    max-height: 455px;
    overflow: auto;

    &__remove-max-height {
      max-height: none;
      overflow: visible;
      padding-top: 10px;
    }

    @include rwd(tablet, true) {
      padding: 10px;
      background: $white;
      border-radius: 4px;
    }

    @include rwd(tablet) {
      max-height: none;
      overflow: visible;
    }
  }

  &--info {
    color: $theme-gray;
    margin-right: 5px;
  }

  .mobile-trigger {
    @include rwd(tablet, true) {
      display: none !important;
    }
  }

  .desktop-trigger {
    i {
      &:before {
        font-size: 15px;
      }
    }
  }
}
