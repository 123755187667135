@import "node_modules/select2/dist/css/select2";

.input-helper .select2-container{
  width: 100% !important;
  & + label {
    top: 0;
    color: $primary;
    font-size: rem-calc(11);
  }
}

.select2-container--default .select2-selection--single .select2-selection__rendered{
  line-height: 40px;
}

.select2-selection__arrow{
  display: none;
}