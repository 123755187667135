#search-engine-mobile {
  @include rwd(tablet, true) {
    display: none;
  }
}

.search-engine {
  height: 100%;
  @include flex(flex-start, center, false);
  z-index: 2;
  text-align: left;
  background-color: #fff;

  @include rwd(tablet) {
    height: 50px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 10;
    @include transitions(opacity, transform);
    transform-origin: top;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: scaleY(0);

    &--mobile-visible {
      opacity: 1;
      transform: scaleY(1);
      pointer-events: auto;
      visibility: visible;
    }
  }

  .search-mark {
    position: absolute;
    left: 29px;
    top: 50%;
    transform: translateY(-50%);

    @include rwd(tablet) {
      left: 17px;
    }

    #{$icon-selector} {
      color: $theme-gray;
    }
  }

  [data-search-engine-toggle-mobile] {
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);

    @include rwd(tablet, true) {
      display: none;
    }
  }

  input {
    height: 100%;
    width: 100%;
    padding-left: 60px;
    border: none;
    outline: none;

    @include rwd(tablet) {
      padding-left: 45px;
      padding-right: 45px;
      margin-top: -1px;
    }
  }

  &__results {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background-color: #fff;
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, .05);
    padding: 13px 60px 24px 60px;
    @include transitions(opacity, transform);
    opacity: 1;
    transform: translateY(0);
    max-height: 100vh;
    max-height: calc(100vh - 51px);
    max-height: calc(var(--vh, 1vh) * 100 - #{$desktop-web-header-height});

    overflow: auto;

    @include rwd(tablet) {
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100 - 50px - #{$mobile-web-dock-height});
      padding-top: 5px;
      padding-left: 45px;
      padding-right: 45px;
    }

    @include rwd(medium) {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 10px;
    }

    &.results-hidden {
      @include rwd(tablet, true) {
        opacity: 0;
        transform: translateY(10px);
        visibility: hidden;
        pointer-events: none;
      }
    }
  }

  &__group {
    margin-top: 22px;

    &:first-child {
      margin-top: 0;
    }

    &-heading {
      margin-bottom: 6px;
    }
  }

  &__message {
    @extend %text-small;

    &--danger {
      color: $danger;
    }
  }

  a {
    &.highlighted {
      outline: 2px solid $theme;
    }
  }
  .grid{
    display: flex;
  }
}