.header {
  $block: &;
  position: relative;
  z-index: 9;
  width: 100%;

  @include rwd(tablet) {
    //display: none !important;
    position: sticky;
    top: 0;
  }

  &__layout {
    @include flex(space-between, stretch, false);
    background-color: $white;

    @include rwd(tablet) {
      display: none;
    }

    &--mobile {
      display: none;
      @include rwd(tablet) {
        @include flex(flex-start, flex-start, false, true);
        display: block;
      }
    }

    &--box-shadow {
      @include rwd(tablet) {
        box-shadow: 0 0 20px rgba(229,233,235, 0.8);
      }
    }
  }

  &__top {
    @include flex(space-between, stretch, false);
    padding: 10px 5px;

    .av {
      cursor: pointer;
    }
  }

  &__bottom {
    overflow: auto;
    scrollbar-width: none;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__left {
    flex-grow: 1;

    @include rwd(tablet) {
      @include flex(flex-start, center, false);
      min-width: 0;
    }

    .button {
      min-width: 40px;
    }
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__right {
    @include flex(flex-end, center, false);
    padding: 0 15px;
    flex-shrink: 0;
  }

  &__nav {
    @include flex(flex-start, center, false, false, true);
    width: 100%;

    &--item {
      flex-shrink: 0;
      position: relative;
      overflow: hidden;
      padding: 10px 5px 15px 5px;
      margin: 0 10px;

      &:first-child {
        margin-left: 15px;

        @include rwd(phone) {
          margin-left: 10px;
        }
      }

      @include transition(color);
      color: $gray-600;

      @include on-event() {
        color: $gray-900;
      }
    }

    &--active {
      &:before {
        content: '';
        position: absolute;
        border-radius: 3px;
        height: 6px;
        bottom: -3px;
        left: 0;
        right: 0;
        background: $theme;
      }
    }
  }

  &__actions {
    @include flex(flex-end, center, false);
    margin-right: 5px;
    padding-right: 15px;
    position: relative;

    &:after {
      content: '';
      width: 1px;
      height: 16px;
      background-color: $theme-gray-lighter;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }

  &__action {
    margin: 0 7px;
  }
}

.header-full-content {
  background-color: #fff;
  padding: 10px 10px;
  width: 100%;

  @include rwd(tablet) {
    display: none;
  }
}
