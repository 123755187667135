.avatar {
    $selector: &;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: inline-block;
    text-align: center;
    position: relative;
    vertical-align: middle;
    background: $primary;

    span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        color: $white;
        font-size: rem-calc(10);
        font-weight: 600;
    }

    &__unset__font span {
        font-size: unset;
    }

    @at-root #{selector-unify(&, a)} {
        @include on-event() {
            #{$selector}__info-top, #{$selector}__info-bottom {
                @include transition(color);
                color: $primary;
            }
        }
    }
    &.bigger {
        width: 36px;
        height: 36px;
        span {
            font-size: rem-calc(12);
        }
    }
    &--center {
        margin-left: auto;
        margin-right: auto;
        #{$selector}__image {
            margin-left: auto;
            margin-right: auto;
        }
    }
    &--image-as-children {
        border-radius: 0;
        background-color: transparent;
    }
    &--image {
        display: flex;
        align-items: center;
        width: auto;
        height: auto;
        @include rwd(medium) {
            align-items: flex-start;
        }
        #{$selector}__image {
            width: 150px;
            height: 150px;
            flex: 0 0 150px;
            overflow: hidden;
            border-radius: 50%;
            box-shadow: 0 0 10px rgba(0, 0, 0, .03);
            position: relative;
            img {
                max-width: 100%;
            }
            @include rwd(1250px) {
                width: 100px;
                height: 100px;
                flex: 0 0 100px;
            }
            @include rwd(tablet) {
                width: 75px;
                height: 75px;
                flex: 0 0 75px;
            }
            @include rwd(small) {
                flex: 0 0 50px;
                width: 50px;
                height: 50px;
            }
        }
        &.smaller {
            #{$selector}__image {
                width: 50px;
                height: 50px;
                flex: 0 0 50px;
            }
        }
        &.smaller-2x {
            #{$selector}__image {
                width: 35px;
                height: 35px;
                flex: 0 0 35px;
            }
        }
        &.tiny {
            #{$selector}__image {
                width: 25px;
                height: 25px;
                flex: 0 0 25px;
            }
        }
        &.medium {
            #{$selector}__image {
                width: 80px;
                height: 80px;
                flex: 0 0 80px;
                @include rwd(tablet) {
                    flex: 0 0 70px;
                    width: 70px;
                    height: 70px;
                }
                @include rwd(small) {
                    flex: 0 0 50px;
                    width: 50px;
                    height: 50px;
                }
            }
        }
        #{$selector}__info {
            $avatar: #{$selector}__info;
            padding-left: 10px;
            text-align: left;
            color: $text;
            a {
                white-space: nowrap;
                color: $text;
                @include link-hover($primary);
            }
            &-top {
                @include font(14, 18, 0);
                font-weight: 500;
                * {
                    margin: 0;
                    padding: 0;
                    display: block;
                }
            }
            &-bottom {
                margin-top: 2px;
                @include font(11, 12, 0);
                color: $secondary;
                a {
                    @include font(12, 12, 0);
                    color: $secondary;
                    @include link-hover($primary);
                }
                * {
                    margin: 0;
                    padding: 0;
                    font-weight: 400;
                }
                #{$icon-selector} {
                    margin-top: -3px;
                }
            }
            &.bigger {
                #{$avatar}-top {
                    @include font(15, 18, 0);
                }
                #{$avatar}-bottom {
                    @include font(13, 15, 0);
                }
            }
        }
    }
    &__initials {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
        font-weight: 700;
        color: #fff;
        z-index: 2;
    }
    &--color {
        &-parent {
            #{$selector}__image {
                background-color: $guardian-color;
            }
            #{$selector}__initials {
                color: #fff;
            }
        }
        &-children {
            #{$selector}__image {
                background-color: $children-color;
            }
            #{$selector}__initials {
                color: #fff;
            }
        }
        &-danger {
            #{$selector}__image {
                background-color: $danger;
            }
            #{$selector}__initials {
                color: #fff;
            }
        }
        &-gray {
            #{$selector}__image {
                background-color: $gray-500;
            }
            #{$selector}__initials {
                color: #fff;
            }
        }
        &-theme {
            #{$selector}__image {
                background-color: $primary;
            }
            #{$selector}__initials {
                color: #fff;
            }
            @include on-event() {
                #{$selector}__info-top, #{$selector}__info-bottom {
                    @include transition(color);
                    color: $primary;
                }
            }
        }
        #{$selector}__initials {
            @include fontauto(50, 40);
            @include rwd(large) {
                @include fontauto(35, 40);
            }
            @include rwd(tablet) {
                @include fontauto(22, 40);
            }
            @include rwd(small) {
                @include fontauto(16, 40);
            }
        }
        &.medium {
            #{$selector}__initials {
                @include fontauto(28, 40);
                @include rwd(tablet) {
                    @include fontauto(22, 40);
                }
                @include rwd(small) {
                    @include fontauto(16, 40);
                }
            }
        }
        &.smaller {
            #{$selector}__initials {
                @include fontauto(16, 30);
            }
        }
        &.smaller-2x {
            #{$selector}__initials {
                @include fontauto(14, 30);
            }
        }
        &.tiny {
            #{$selector}__initials {
                @include fontauto(11, 11);
            }
        }
    }
}

.item-with-avatar {
    min-height: 35px;
    position: relative;
    padding-left: rem-calc(35);
    .avatar {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    &.bigger {
        padding-left: rem-calc(50);
    }

    span {
        min-height: 35px;
        @include flex(flex-start, center);
    }
}
