.flatpickr-calendar {
  border: none !important;
  box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12) !important;
  border-radius: 2px !important;
  padding: 10px 0 !important;
  &.noCalendar {
    padding: 0 !important;
  }
  &:after, &:before {
    display: none !important;
  }
  .flatpickr-rContainer {
    padding: 20px 10px !important;
  }
  .flatpickr-days, .dayContainer {
    width: 287px !important;
    min-width: 287px !important;
    max-width: 287px !important;
  }
  .flatpickr-weekday {
    color: rgba(0,0,0,.38) !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(0,0,0,.1) !important;
    padding-bottom: 10px;
  }
  .flatpickr-months {
    position: relative;
    .flatpickr-current-month {
      position: absolute !important;
      left: 12px !important;
      text-align: left;
      font-size: 15px !important;
      font-weight: 400 !important;
      text-transform: uppercase;
      padding: 7px 0 0 0;
      top: 0 !important;
      .cur-month {
        @include on-event() {
          background-color: transparent;
        }
      }
    }
    .flatpickr-prev-month {
      left: auto !important;
      right: 40px !important;
      &:hover {
        svg {
          fill: $primary !important;
        }
      }
    }
    .flatpickr-next-month {
      &:hover {
        svg {
          fill: $primary !important;
        }
      }
    }
  }
  .flatpickr-day {
    color: #7b7b7b !important;
    &.prevMonthDay, &.nextMonthDay {
      color: rgba(57,57,57,0.3) !important;
    }
    &.selected {
      background-color: darken($primary, 5%) !important;
      border-color: darken($primary, 5%) !important;
      color: #fff !important;
      &:hover {
        color: $primary !important;
      }
    }
    &:hover {
      background: rgba(0,0,0,.04) !important;
      border-color: rgba(0,0,0,.01) !important;
    }
    &.today {
      border-color: rgba(0,0,0,.25) !important;
      &:hover {
        background: rgba(0,0,0,.04) !important;
        color: #7b7b7b !important;
      }
    }
  }
  .numInputWrapper {
    .arrowUp, .arrowDown {
      border: none !important;
    }
    .arrowUp {
      &:after {
        border-bottom-color: #666666 !important;
        padding-top: 3px !important;
        top: auto;
      }
    }
    .arrowDown {
      &:after {
        border-top-color: #666666 !important;
        margin-top: -3px !important;
      }
    }
  }
}

.flatpickr-calendar.open {
  z-index: 9999999999 !important;
}

[data-siblings-container] {
  position: relative;
  .flatpickr-calendar {
    left: 50% !important;
    top: 0 !important;
    transform: translateX(-50%);
    opacity: 0;
    animation: fpFadeInDown50 200ms cubic-bezier(.23,1,.32,1) !important;
    &.open {
      opacity: 1;
    }
  }
}

@keyframes fpFadeInDown50 {
  from {
    opacity: 0;
    transform: translateX(-50%) translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translate3d(0, 0, 0);
  }
}


