.presence-list-table {
  $list: &;

  .row {
    &:not(.row--without-styles) {
      @include flex(space-between, stretch, false);
      flex-wrap: nowrap;
      border: none;
      padding-right: 0;
      @include rwd(small) {
        padding-left: 0;
        margin-left: 5px;
        margin-right: 5px;
      }
    }

      &.head {
        align-items: center;
      }

      &__details {
        padding: 7px 5px !important;
      }

      &__actions {
        flex-shrink: 0;
        padding: 0 !important;
        @include flex(flex-end, center, false);

        .label {
          margin-right: 8px;
          text-align: center;
        }
      }

      #{$list}__name, #{$list}__name a {
        font-weight: 500;
        @include font(14, 16, 0);
        @include rwd(340px) {
          @include font(13, 15, 0);
        }
      }
  }

  &__group {

  }
  .badges {
    margin-right: 5px;
    border-right: 1px solid $theme-gray-lighter;
    padding-right: 5px;

    @include rwd(medium) {
      display: none !important;
    }
  }
  .child-event-actions {
    height: 100%;
    [data-toggle], .btn {
      height: 100%;
    }
  }
  .child-event-action {
    width: 40px;
    height: 40px;
    @include flex(center, center, false);
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    @include transition(background-color);
    background-color: $theme;
    margin: 0 5px;

    #{$icon-selector} {
      font-size: 20px;
      @include transition(opacity);
      color: $white;
      @include rwd(medium) {
        display: none;
      }
    }
    .child-event-action-counter{
      display: none;
      font-weight: bold;
      @include rwd(medium) {
        display: inline;
      }
    }
    &[data-child-event-action-count="0"] {
      #{$icon-selector} {
        @include rwd(medium) {
          display: inline;
        }
      }
      .child-event-action-counter{
        @include rwd(medium) {
          display: none;
        }
      }
    }

  }
  .presence-actions {
    @include flex(flex-end, center, false);
    height: 100%;
    // colors depends on state
    &--daily {
      &[data-current-state="0"] {
        [data-presence-absent] {
          background-color: $danger;
          color: #fff;
        }

        [data-presence-present] {
          #{$icon-selector} {
            opacity: .3;
          }
        }
      }

      &[data-current-state="1"] {
        [data-presence-present] {
          background-color: $success;
          color: #fff;
        }

        [data-presence-absent] {
          #{$icon-selector} {
            opacity: .3;
          }
        }
      }
    }

    &--hourly {
      &[data-current-state="0"], &[data-current-state="-1"] {
        [data-presence-present] {
          background-color: $success;
          color: #fff;
        }
      }

      &[data-current-state="1"] {
        [data-presence-absent] {
          background-color: $danger;
          color: #fff;
        }
      }
    }
  }

  .presence-loader {
    width: 50px;

    .lds-ellipsis {
      margin: 0 auto;
    }
  }

  .presence-action {
    width: 50px;
    height: 100%;
    @include flex(center, center, false);
    border-radius: 1px;
    overflow: hidden;
    cursor: pointer;
    @include transition(background-color);

    #{$icon-selector} {
      @include transition(opacity);
      font-size: 18px;
    }

    &[data-presence-absent] {
      background-color: #ffefeb;
      color: #f9795c;
    }

    &[data-presence-present] {
      background-color: #f3ffea;
      color: #93d95f;
    }

    &--time {
      font-weight: 700;

      &[data-presence-absent] {
        background-color: #ffefeb !important;
        color: #b65641 !important;
      }

      &[data-presence-present] {
        background-color: #f3ffea !important;
        color: #4c762c !important;
      }
    }
  }
}

.choose-activity{
  .button {
    padding-right: 10px;
    border-radius: 24px;
  }
  input {
    &:not(:checked) + .button{
      background: transparent !important;
    }
    &:checked + .button{
      .button__text{
        color: #FFF;
      }
    }
  }
}