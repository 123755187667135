@charset "UTF-8";
@import "utils";

@import "utils/reboot";
@import "vendors/fontawesome";
@import "vendors/fancybox";
@import "base/base";
@import "base/text";
@import "base/wcag-theme";
@import "base/wcag-text";
@import "layout/global";
@import "layout/header";
@import "layout/mobile-dock";
@import "layout/sidebar";
@import "components/notifications";
@import "components/navigation";
@import "components/message";
@import "components/field";
@import "components/dropdown";
@import "components/avatar";
@import "components/av";
@import "components/button";
@import "components/main-content";
@import "components/heading";
@import "components/popup";
@import "components/popup-table-info";
@import "components/loader";
@import "components/quill-editor";
@import "components/ai";
@import "~flatpickr/dist/flatpickr.min.css";
@import "~flatpickr/dist/plugins/monthSelect/style.css";
@import "components/flatpickr";
@import "components/table-sortable";
@import "components/special-checkboxes";
@import "vendors/litepicker";
@import "components/dishes-select";
@import "components/presence-list-table";
@import "components/badge";
@import "components/search-engine";

@import "components/toastr";
@import "components/tooltip";
@import "~toastr/build/toastr.min.css";

@import "vendors/select2";

.field {
  select {
    background-image: none !important;
    line-height: 17px !important;
  }
}

.popup {
  &__content {
    form {
      margin-top: 0;
    }

    .fancybox-close-small svg path {
      fill: #fff;
    }
    &__standard{
      min-width: 648px;
      .popup-table-info{
        max-width: 648px;
        @include rwd(medium) {
          max-width: 100%;

        }
      }
      @include rwd(medium) {
        min-width: 100px;
      }
    }
    .searchable-container {
      .selectable-items {
        position: relative;
      }
    }
  }
}

.flatpickr-calendar.open {
  z-index: 9999999999999;
}

.flatpickr-monthSelect-theme-light{
  .flatpickr-rContainer{
    padding: 0 !important;
  }
}

label {
  margin-bottom: 0;
}

.select2-container{
  z-index: 9999999999;
}

.select2-selection--single {
  background: #fff;
  outline: none;
  box-shadow: none;
  border: 1px solid #e9ecef !important;
  border-radius: 5px;
  font-size: 0.875rem;
  height: 40px !important;
  width: 100%;
  transition: border .3s linear;
  -webkit-appearance: none;
  color: #141516;
  font-weight: 400;
  text-indent: 0;
  @include reset('select');
  padding: 0 28px 0 10px;
}

.select2-selection--single {
  &:after {
    content: '';
    position: absolute;
    right: 10px;
    top: 19px;
    width: 0;
    height: 0;
    border-top: 5px solid #495057;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    pointer-events: none;
    z-index: 99999999999;
  }
}

.fancybox-loading{
  z-index: 99999999999 !important;
}

[data-changer-hidden]{
  display: none;
}

.search-engine{
  [type="text"]{
    font-size: 0.875rem;
  }
}

.btn{
  &--prev {
    &:before {
      @include faIcon('fal', 'angle-left');
      font-size: 20px;
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      margin-top: 1px;

      @media screen and (max-width: 450px) {
        left: 8px;
      }
    }

    padding-left: 32px !important;

    @media screen and (max-width: 450px) {
      padding-left: 22px !important;
    }
  }

  &--next {
    &:before {
      @include faIcon('fal', 'angle-right');
      font-size: 20px;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      margin-top: 1px;

      @media screen and (max-width: 450px) {
        right: 8px;
      }
    }

    padding-right: 32px !important;

    @media screen and (max-width: 450px) {
      padding-right: 22px !important;
    }
  }

  &--add {
    &:before {
      @include faIcon('fal', 'plus');
    }
  }

  &--edit {
    &:before {
      @include faIcon('fal', 'pencil');
    }
  }

  &--archive {
    &:before {
      @include faIcon('fal', 'archive');
    }
  }

  &--remove {
    &:before {
      @include faIcon('fal', 'trash-alt');
    }
  }

  &--archive, &--remove {
    &:before {
      margin-right: rem-calc(3);
    }

    color: $danger;
    background: none;
    @include on-event() {
      color: #fff;
      background-color: $danger;
    }

    &.btn--circle-icon {
      width: 41px;
      text-align: center;
      #{$icon-selector} {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
  &--icon {
    #{$icon-selector} {
      &:before {
        margin-right: rem-calc(3);
      }
    }
  }
}

.noscrollbar{
  scrollbar-width: none;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
}
