.message {
  $block: &;
  background: #fff;
  border-radius: 4px;
  text-align: left;
  margin: 15px 0;
  padding: 10px 20px;
  @include font(13, 15, 0);

  &--neutral {
    border-left: 3px solid $gray-500;
  }

  &--danger {
    border-left: 3px solid $danger;
    background-color: rgba($danger, .1);
  }

  &--success {
    border-left: 3px solid $success;
    background-color: rgba($success, .1);
  }

  &--warning {
    border-left: 3px solid $warning;
  }

  &--info {
    border-left: 3px solid $info;
    background-color: rgba($info, .07);
  }

  &--with-bg {
    background-color: $theme-bg;
  }

  &--with-button {
    @include flex(flex-start, center, true);

    #{$block}__text {
      margin-right: 20px;
    }
  }
}
