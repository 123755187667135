.fixed-navigation {
  @include rwd(tablet) {
    margin-top: 42px;
  }

  @include rwd(small) {
    margin-top: 52px;
  }

  &--kid-profile {
    @include rwd(tablet) {
      margin-top: 32px;
    }

    @include rwd(small) {
      margin-top: 42px;
    }
  }
}

.navigation-reverse-order {
  @include flex(flex-start, stretch, false, true, false);

  @include rwd(tablet, true) {
    flex-direction: column-reverse;
  }
}

.navigation {
  &__container {
    @include rwd(tablet, true) {
      position: relative;
      width: 100%;
      margin-bottom: 20px;

      &:before {
        content: '';
        left: -60px;
        right: -60px;
        bottom: 0;
        transform: translateY(2px);
        height: 2px;
        position: absolute;
        display: block;
        background-color: $gray-200;

        @include rwd(large) {
          left: -30px;
          right: -30px;
        }
      }
    }

    scrollbar-width: none;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      display: none;
    }

    &:not(.navigation__container--not-mobile-sticky) {
      @include rwd(tablet) {
        margin: -20px -20px 0 -20px;
        background: $white;
        position: fixed;
        z-index: 100;
        width: 100vw;
        box-shadow: 0 0 20px rgba(229, 233, 235, 0.8);
      }

      @include rwd(small) {
        margin: -10px -10px 0 -10px;
      }
    }

    @include rwd(tablet) {
      overflow-x: auto;
    }

    &--gap {
      @include rwd(tablet, true) {
        margin-bottom: 30px;
      }
    }

    &--not-mobile-sticky {
      @include rwd(tablet) {
        .navigation {
          &__item {
            padding: 8px 12px;
            margin: 6px;
            border-radius: 8px;
            background-color: #EEF3F9;
          }

          &--active {
            background-color: #BCD0E9;
            color: #194E91 !important;

            &:before {
              display: none;
            }
          }
        }
      }
    }
  }

  @include rwd(tablet) {
    flex-wrap: nowrap;
  }

  @include flex(flex-start, center, true, false, true);
  width: 100%;

  &__item {
    flex-shrink: 0;
    position: relative;

    @include rwd(tablet, true) {
      padding: 10px 0 10px 0;
      margin-right: 30px;
    }

    @include rwd(tablet) {
      padding: 10px 5px 15px 5px;
      margin: 0 10px;

      &:first-child {
        margin-left: 15px;
      }
    }

    @include rwd(phone) {
      &:first-child {
        margin-left: 10px;
      }
    }

    @include transition(color);
    color: $gray-800;

    @include on-event() {
      color: $gray-900;
    }
  }

  &--active {
    color: $theme !important;

    &:before {
      content: '';
      position: absolute;
      border-radius: 2px;
      height: 2px;
      bottom: -2px;
      left: 0;
      right: 0;
      background: $theme;

      @include rwd(tablet) {
        border-radius: 3px;
        height: 6px;
        bottom: -3px;
      }
    }
  }

  &--scroll{
    scrollbar-width: none;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      display: none;
    }
    @include rwd(tablet) {
      overflow-x: auto;
    }
  }
}
