#toast-container > div.toast {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  box-shadow: 0 0 15px rgba(#000, .1) !important;
  opacity: 1 !important;
  @include transition(color);
  border-radius: 6px !important;
  background-image: none !important;
  padding: 13px 15px !important;
  width: 400px !important;
  @include rwd(large) {
    width: 100% !important;
  }
}

#toast-container {
  z-index: 999999999999; // over fancybox popup
  @include rwd(large) {
    left: 15px !important;
    top: 2px !important;
    right: 15px !important;
  }
}

.toast-title {
  display: none !important;
}

.toast-success {
  border-color: $success;
  color: $success;
}

.toast-error {
  border-color: $danger;
  color: $danger;
}

.toast-info {
  border-color: $info;
  color: $info;
}

.toast-warning {
  border-color: $warning;
  color: $warning;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  background-color: #fff !important;
  opacity: .8 !important;
}

.toast-title {
  font-weight: 700
}

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word
}
