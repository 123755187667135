.loader {
  border: 8px solid $primary;
  border-top-color: #fff;
  border-bottom-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: loader 1.1s linear infinite;
  background: #fff;
}
.loader-transparent-bg {
  @include flex(center, center, false);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(#F4F8FB, 0.6);
  margin: 0;
  z-index: 100;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

@keyframes lds-ellipsis2-small {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(10px, 0);
  }
}

.lds-ellipsis {
  position: relative;
  width: 64px;
  height: 64px;
  margin: 30px auto;
  text-align: center;
  display: table;
  div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: $primary;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    &:nth-child(1) {
      left: 6px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 6px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 26px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 45px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }

  &--white {
    div {
      background: #fff;
    }
  }

  &--small {
    width: 32px;
    height: 32px;
    margin: 15px auto;
    div {
      width: 5px;
      height: 5px;
      top: 14px;
      &:nth-child(1) {
        left: 3px;
      }
      &:nth-child(2) {
        left: 3px;
        animation: lds-ellipsis2-small 0.6s infinite;
      }
      &:nth-child(3) {
        left: 13px;
        animation: lds-ellipsis2-small 0.6s infinite;
      }
      &:nth-child(4) {
        left: 22px;
      }
    }
  }
}
