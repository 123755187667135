body, a, p, li, label, input, select,
textarea, legend, code, pre,
dd, dt, span, blockquote, .side-nav__link,
.branches-list, .kid-identities__row,
.msg, .message, .toast, .text,
.avatar__info-bottom, .popup-table-info__child-group,
input::placeholder, .button, .btn, .row__details,
.head, .row, .search-column, .lab {
  .font-medium-wcag & {
    font-size: 120% !important;
  }
}

h1, h2, h3, h4, h5, h6,
h1 span, h2 span, h3 span,
h4 span, h5 span, h6 span,
.post__title,  .avatar__info-top {
  .font-medium-wcag & {
    font-size: 125% !important;
  }
}

.av__name {
  .font-medium-wcag & {
    font-size: 175% !important;
  }
}

.dashboard-summary__content, .messages {
  .font-medium-wcag & {
    * {
      font-size: 120% !important;
    }
  }
}

body, a, p, li, label, input, select,
textarea, legend, code, pre,
dd, dt, span, blockquote, .side-nav__link,
.branches-list, .kid-identities__row,
.msg, .message, .toast, .text,
.avatar__info-bottom, .popup-table-info__child-group,
input::placeholder, .button, .btn,
.row__details, .head, .row, .search-column, .lab {
  .font-large & {
    font-size: 125% !important;
  }
}

h1, h2, h3, h4, h5, h6,
h1 span, h2 span, h3 span,
h4 span, h5 span, h6 span,
.post__title, .avatar__info-top {
  .font-large & {
    font-size: 130% !important;
  }
}

.av__name {
  .font-large & {
    font-size: 200% !important;
  }
}

.dashboard-summary__content, .messages {
  .font-large & {
    * {
      font-size: 125% !important;
    }
  }
}
