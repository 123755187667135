.table-sortable {
  [data-sortable] {
    padding-right: 20px;
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    @include transition(color);
    @include on-event() {
      color: $primary-light;
    }

    #{$icon-selector} {
      @include font(14, 14, 0);
      order: 2;
    }

    .fa-caret-up {
      margin-left: 6px;
    }
  }

  [data-sortable="ASC"] {
    .fa-caret-up {
      color: $primary !important;
    }
  }

  [data-sortable="DESC"] {
    .fa-caret-down {
      color: $primary !important;
    }
  }

  .mobile-sorter {
    background-color: #fff !important;
    overflow: hidden;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    border: none !important;
    @include flex(space-between, center, true);
    @include rwd(tablet, true) {
      display: none !important;
    }
    @include rwd(tablet) {
      display: none;
    }

    &.active {
      display: flex;
    }

    & > *:not([data-sortable]) {
      display: none;
    }

    [data-sortable] {
      padding: 15px 0 15px 25px;
      width: 48%;
      cursor: pointer;
      border-radius: 5px;
      @include rwd(small) {
        width: 100%;
      }

      #{$icon-selector} {
        display: none;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        margin: 1px 0 0 0;
        font-size: 8px;
      }
    }

    [data-sortable="ASC"] {
      background-color: #eff4ff;

      .fa-caret-up {
        display: block;
      }
    }

    [data-sortable="DESC"] {
      background-color: #eff4ff;

      .fa-caret-down {
        display: block;
      }
    }
  }
}