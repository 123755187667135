.application {
  padding: 0;
  padding: var(--sait) var(--sair) var(--saib) var(--sail);
  height: 100%;
  width: 100%;
  @include flex(flex-start, stretch, false);

  @include rwd(tablet) {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }

  &--height-screen {
    @include rwd(tablet) {
      height: calc(var(--vh, 1vh) * 100);
    }
  }

  &--center-layout {
    max-width: 600px;
    margin: 0 auto;
    padding: 50px 20px;
    display: block;

    @include rwd(tablet) {
      padding-top: 0;

      .page-component {
        margin-top: 30px !important;
      }
    }
  }

  &--full {
    .sidebar, .header {
      @include rwd(tablet, true) {
        display: none !important;
      }
    }

    .mobile-context-selector {
      @include rwd(tablet) {
        display: none !important;
      }
    }
  }

  &__content {
    flex-basis: auto;
    flex-grow: 1;
    @include flex(flex-start, flex-start, false, true);

    max-width: calc(100vw - #{$sidebar-width});

    @include rwd(tablet) {
      width: 100%;
      max-width: 100%;
    }

    &--without-right-sidebar {
      max-width: calc(100vw - #{$sidebar-left-width});

      @include rwd(tablet) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.main-heading {
  background-color: $theme-bg;
  padding: 25px 60px 5px 60px;
  margin: 0 -60px 20px -60px;
  position: relative;
  z-index: 2;

  @include rwd(large) {
    padding-left: 30px;
    padding-right: 30px;
    margin-left: -30px;
    margin-right: -30px;
  }

  @include rwd(tablet) {
    position: fixed;
    z-index: 10;
    bottom: 70px;
    right: 10px;
    padding: unset;
    margin: unset;
    background: transparent;
  }

  &--hidden {
    display: none;
  }

  &--only-mobile {
    @include rwd(tablet, true) {
      display: none;
    }
  }

  &--only-desktop {
    @include rwd(tablet) {
      display: none;
    }
  }

  &--higher-zindex {
    z-index: 6;
  }

  &__back {
    @include rwd(tablet, true) {
      display: none !important;
    }

    position: absolute !important;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 11;
  }

  .heading-with-element {
    @include font(20, 24, 0);
    font-weight: bold;
    margin: 0;
  }

  .text {
    margin-top: 8px;

    @include rwd(tablet) {
      display: none;
    }
  }

  .additional-links {
    margin: 8px -10px 0 -10px;
    @include flex(flex-start, center, true);

    @include rwd(tablet) {
      display: none;
    }

    &__item {
      position: relative;
      padding: 0 10px;

      &:after {
        content: '•';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -3px;
        font-size: 12px;
        color: $theme-gray;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
}

.userprofile {
  $block: &;
  @include flex(flex-start, center, false);
  padding: 8px 10px;

  &__right {
    padding-left: 15px;
  }

  &__name {
    @extend %text-base;
    font-weight: 700;
    color: $theme-dark;
  }

  &--danger {
    background-color: lighten($danger, 35);

    .text {
      color: lighten($danger, 20);
    }

    #{$block}__name {
      color: $danger;
    }
  }
}

.page-boxes {
  @include flex(flex-start, flex-start, true);
  margin-left: -1%;
  margin-right: -1%;

  .page-box {
    margin-left: 1%;
    margin-right: 1%;
  }
}

.page-box-title {
  @include font(15, 17, 0);
  font-weight: 700;
  margin-bottom: 8px;
  &.double-margin-bottom{
    margin-bottom: 16px;
  }
  span {
    font-weight: 400;
  }
  &.chart-title{
    display: flex;
    align-items: center;
    .diet-box__number{
      margin-left: 15px;
    }
  }

  &--phone-center {
    @include rwd(phone) {
      @include font(17, 18, 0);
      margin-bottom: 12px;
      text-align: center;
    }
  }
  &.inline{
    display: inline-block;
  }
}

.page-box {
  border-radius: 4px;
  box-shadow: 0 0 5px 5px rgba(#E5E9EB, .15);
  background-color: #fff;
  display: block;
  width: 100%;

  &--small {
    padding: 22px 16px;

    @include rwd(large) {
      padding: 20px 15px;
    }
  }

  &--medium {
    padding: 28px 36px;

    @include rwd(large) {
      padding: 20px 15px;
    }
  }

  &--padding {
    &-y {
      &-top {
        &-none {
          padding-top: 0;

          &-ph {
            @include rwd(phone) {
              padding-top: 0;
            }
          }
        }
      }
    }
  }

  &--transparent {
    background-color: transparent;
    box-shadow: none;
  }

  &__layout {
    position: relative;
  }

  &--link {
    padding: 13px 17px;
    cursor: pointer;
    @include transition(background-color);

    #{$icon-selector} {

    }

    @include on-event() {
      background-color: $theme-light;
    }
  }

  &--relative {
    position: relative;
  }

  &--heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.branch-box {
  display: block;
  width: 100%;
  position: relative;

  &__link {
    display: block;
    width: 100%;

    .av__name {
      @include transition(color);
    }

    @include on-event() {
      .av__name {
        color: $theme;
      }
    }
  }

  &__details {
    margin-top: 15px;
  }

  .labels {
    margin-top: 8px;
  }
}

.cta-box {
  border-radius: 4px 4px 40px 4px;
  padding: 32px 100px 32px 26px;
  font-family: $font-family-redesign;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 190px;
  height: 100%;
  @include transition(opacity);

  @include on-event() {
    opacity: .9;
  }

  &:before {
    content: '';
    position: absolute;
    right: -200px;
    bottom: -150px;
    width: 328px;
    height: 260px;
    border-radius: 50%;
    background-color: rgba(#fff, .1);
  }

  &--blue {
    background-color: $theme;
  }

  &--orange {
    background-color: $theme-secondary;
  }

  &__details {
    position: relative;
    z-index: 2;
  }

  &__head {
    @include font(16, 19, 0);
    margin-bottom: 8px;
    color: #fff;
    font-weight: 700;
  }

  &__text {
    @extend %text-medium;
    color: #fff;
  }

  &__button {
    margin-top: 30px;
    display: block;
  }

  &__image {
    pointer-events: none;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}

.diet-box {
  @include flex(flex-start, flex-start, false);
  padding: 16px;
  background-color: rgba($theme, .07);
  border-radius: 4px;

  &-status {
    @include flex(flex-start, center, false);

    .lab {
      flex-shrink: 0;
      margin-right: 16px;
    }

    .text, .button {
      margin-right: 16px;
    }
  }

  &__left {
    flex-shrink: 0;
    padding-right: 16px;
  }

  &__right {

  }

  &__number {
    border-radius: 50%;
    background-color: $theme;
    @include flex(center, center, false);
    font-weight: 700;
    @include font(16, 19, 0);
    color: #fff;
    width: 40px;
    height: 40px;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__item {
    margin: 2px 0;
  }
}

.info-box {
  @extend .message;
  @extend .message--info;
  @include flex(space-between, center, false);
  @include transition(background-color);
  margin: 0 !important;

  &--padding {
    &-biger {
      padding: 20px !important;
    }
  }

  @include rwd(small) {
    padding-left: 10px !important;
    padding-right: 10px !important;
    flex-wrap: wrap;

    &--padding {
      &-biger {
        padding: 15px !important;
      }
    }
  }

  @include on-event() {
    background-color: $theme-light;
  }

  &--no-hover {
    @include on-event() {
      background-color: rgba($info, .07);
    }
  }

  &__item {
    margin-top: 3px;

    &:first-child {
      margin-top: 0;
    }

    &-medium {
      margin-top: 6px;
    }

    &-big {
      margin-top: 9px;
    }

    &-huge {
      margin-top: 16px;
    }
  }

  &__left {
    @include rwd(small) {
      order: 1;
      width: 100%;
    }
  }

  &__right {
    padding-left: 10px;

    @include rwd(small) {
      order: 0;
      padding-left: 0;
      margin-bottom: 4px;
      width: 100%;
    }
  }
}

.recent-payment {
  @include flex(space-between, center, false);
  @include transition(background-color);
  border-radius: 15px 4px 4px 15px;
  padding: 3px 2px 3px 0;

  @include rwd(phone) {
    flex-wrap: wrap;
  }

  @include on-event() {
    background-color: #f9f9f9;
  }

  &__left {
    @include flex(flex-start, center, false);
    flex-shrink: 0;

    @include rwd(phone) {
      order: 1;
      width: 100%;
    }

    .lab {
      margin-right: 12px;
      flex-shrink: 0;
    }
  }

  &__right {
    padding-left: 16px;

    @include rwd(phone) {
      padding-left: 0;
      width: 100%;
      margin-bottom: 5px;
      order: 0;
    }
  }
}

.stats-box {
  &__circle {
    width: vw-calc(80);
    height: vw-calc(80);
    border: 2px solid $theme;
    overflow: hidden;
    border-radius: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    @include rwd(large) {
      width: 80px;
      height: 80px;
    }

    &--chart {
      border: none;
    }
  }

  &__values {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }

  &__number {
    @include font(26, 26, 0);
    font-weight: 700;
  }

  &__icon {
    color: $theme;
    font-size: 16px;
    line-height: 0;
    margin-top: 2px;
  }

  &__text {
    text-align: center;

    &--success {
      color: $success;
    }

    &--error {
      color: $danger;
    }
  }

  &__heading {
    text-align: center;
    margin-top: 20px;
  }

  .circle-chart {
    width: vw-calc(80);
    height: vw-calc(80);

    @include rwd(large) {
      width: 80px;
      height: 80px;
    }

    &__circle {
      transform: rotate(-270deg);
      transform-origin: center;
      animation: progress 1s linear forwards;
    }
  }
}

.upcoming-birthday {
  &__image {
    position: absolute;
    right: -5px;
    top: -10px;
    z-index: 1;
    pointer-events: none;
    width: 60%;

    @include rwd(tablet) {
      width: 30%;
    }

    @include rwd(phone) {
      width: 50%;
    }
  }

  &__group {
    margin-top: 20px;
  }

  &__items {
    margin-top: 10px;
  }

  &__item {
    @include flex(flex-start, center, false);
    margin-top: 12px;
    @include transition(background-color);
    border-radius: 4px;
    padding: 3px;

    @include on-event() {
      background-color: #f9f9f9;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__left {
    flex-shrink: 0;

    #{$icon-selector} {
      color: $theme-gray;
      font-size: 20px;
    }
  }

  &__right {
    padding-left: 16px;
  }
}

.branch-guide-action {
  background-color: $success;
  color: #fff;
  @include flex(space-between, center, false);
  height: $desktop-web-header-height;
  border-bottom-right-radius: 100px;
  padding-left: 60px;
  padding-right: 35px;
  @extend %text-base;
  @include transition(background-color);
  width: 70%;

  @include rwd(large) {
    padding-left: 30px;
    width: 100%;
  }

  @include rwd(tablet) {
    padding-left: 14px;
    border-bottom-right-radius: 0;
    padding-right: 15px;
  }

  @include on-event() {
    background-color: lighten($success, 10%);
  }

  &__left {
    color: #fff;
  }

  &__right {
    flex-shrink: 0;
    padding-left: 80px;
    @include flex(flex-end, center, false);
    font-weight: 700;
    color: #fff;

    @include rwd(1350px) {
      padding-left: 20px;
    }

    #{$icon-selector} {
      margin-left: 3px;
      font-size: 16px;
    }

    span {
      @include rwd(1300px) {
        display: none;
      }
    }
  }
}

.competition {
  max-width: 1200px;
  padding: 60px 30px;
  margin-left: auto;
  margin-right: auto;

  @include rwd(phone) {
    padding: 30px 15px;
  }

  &--max-width {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .login-copyright {
    margin-top: 15px;
  }

  &__top {
    @include flex(space-between, center, true, false);
    padding: 15px 0;

    h2 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      margin-right: 10px;
    }

    .button {
      margin: 10px 0;
    }
  }

  &__box {
    color: #000;
    display: block;
    height: 100%;

    @include flex(space-between, center, false, true);

    &-top, &-bottom {
      width: 100%;
    }

    &-bottom {
      margin: 25px 0 5px 0;
      width: 100%;
      @include flex(space-between, center);

      .fb-login-wrapper {
        margin: 5px 0;
      }
    }

    &--title {
      @include font(20, 20, 0);
    }

    &--description {
      @include font(14, 22, 0);

      &--max-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.hello-box {
  $block: '.hello-box';
  @include flex(flex-start, center, false);

  &__text {
    @include font(26, 31, 0);
  }

  &__logo {
    flex-shrink: 0;
    margin-left: 10px;
    display: inline-block;
    width: 55px;
    transform: translateY(-1px);

    img {
      width: 100%;
      height: auto;
    }
  }

  &--mobile {
    #{$block}__logo {
      width: 130px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
    }
  }
}

.hello-text {
  background-color: $theme-light;
  padding: 20px 24px;
  border-radius: 6px;
  margin-top: 20px;
}

.counter-inline {
  display: inline-block;
  width: 25px;
  padding-left: 2px;
  text-align: center;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.margin {
  &-none {
    margin: 0 !important;
  }

  &-y {
    &-none {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    &-top {
      margin-top: 20px !important;

      &-ph {
        @include rwd(phone) {
          margin-top: 20px !important;
        }
      }

      &-none {
        margin-top: 0 !important;
      }

      &-small {
        margin-top: 10px !important;
      }

      &-medium {
        margin-top: 30px !important;
      }

      &-big {
        margin-top: 40px !important;
      }

      &-huge {
        margin-top: 50px !important;
      }
    }

    &-bottom {
      margin-bottom: 20px !important;

      &-none {
        margin-bottom: 0 !important;
      }

      &-tiny {
        margin-bottom: 2px !important;
      }

      &-small-2x {
        margin-bottom: 5px !important;
      }

      &-small {
        margin-bottom: 10px !important;
      }

      &-medium {
        margin-bottom: 30px !important;
      }

      &-big {
        margin-bottom: 40px !important;
      }

      &-huge {
        margin-bottom: 50px !important;
      }
    }
  }
  &-x {
    &-none {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    &-left {
      margin-left: 20px !important;

      &-none {
        margin-left: 0 !important;
      }

      &-tiny {
        margin-left: 2px !important;
      }

      &-small-2x {
        margin-left: 5px !important;
      }

      &-small {
        margin-left: 10px !important;
      }

      &-medium {
        margin-left: 30px !important;
      }

      &-big {
        margin-left: 40px !important;
      }

      &-huge {
        margin-left: 50px !important;
      }
    }

    &-right {
      margin-right: 20px !important;

      &-none {
        margin-right: 0 !important;
      }

      &-tiny {
        margin-right: 2px !important;
      }

      &-small-2x {
        margin-right: 5px !important;
      }

      &-small {
        margin-right: 10px !important;
      }

      &-medium {
        margin-right: 30px !important;
      }

      &-big {
        margin-right: 40px !important;
      }

      &-huge {
        margin-right: 50px !important;
      }
    }
  }
}

.padding {
  &-none {
    padding: 0 !important;
  }

  &-y {
    padding-top: 20px !important;
    padding-bottom: 20px !important;

    &-tiny {
      padding-top: 2px !important;
      padding-bottom: 2px !important;
    }

    &-small-2x {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }

    &-small {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }

    &-medium {
      padding-top: 30px !important;
      padding-bottom: 30px !important;
    }

    &-big {
      padding-top: 40px !important;
      padding-bottom: 40px !important;
    }

    &-huge {
      padding-top: 50px !important;
      padding-bottom: 50px !important;
    }

    &-top {
      padding-top: 20px !important;

      &-tiny {
        padding-top: 2px;
      }

      &-small-2x {
        padding-top: 5px !important;
      }

      &-small {
        padding-top: 10px !important;
      }

      &-medium {
        padding-top: 30px !important;
      }

      &-big {
        padding-top: 40px !important;
      }

      &-huge {
        padding-top: 50px !important;

        &-ph {
          @include rwd(phone) {
            padding-top: 50px !important;
          }
        }
      }
    }

    &-bottom {
      padding-bottom: 20px !important;

      &-tiny {
        padding-bottom: 2px;
      }

      &-small-2x {
        padding-bottom: 5px !important;
      }

      &-small {
        padding-bottom: 10px !important;
      }

      &-medium {
        padding-bottom: 30px !important;
      }

      &-big {
        padding-bottom: 40px !important;
      }

      &-huge {
        padding-bottom: 50px !important;
      }
    }
  }

  &-x {
    &-left {
      &-none {
        padding-left: 0 !important;
      }
      &-tiny {
        margin-left: 2px;
      }
      &-small-2x {
        margin-left: 5px;
      }
      &-medium {
        padding-left: 30px !important;
      }
      &-big {
        padding-left: 40px !important;

        &-ph {
          @include rwd(phone) {
            padding-left: 30px !important;
          }
        }
      }
    }
    &-right {
      &-none {
        padding-right: 0 !important;
      }
    }
  }
}

.month-summary {
  @include flex(space-between, flex-start);

  &__item {
    @include flex(space-between, flex-start, false);
    background: $redesign-event-box-bg;
    width: 49%;
    padding: 8px 8px 8px 8px;
    margin-bottom: 10px;

    border-radius: 4px;

    span:first-child {
      margin-right: 15px;
    }
    span:nth-child(2) {
      flex-shrink: 0;
    }


    @include rwd(phone) {
      width: 100%;
      margin-bottom: 0;
      border-radius: 0;

      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}

.event-box {
  @include flex(space-between, baseline, false);
  height: 100%;
  background: $redesign-event-box-bg;
  margin-bottom: 10px;
  padding: 15px 25px;
  border-radius: 4px;
  line-height: 17px;

  &--padding {
    &-x {
      &-tiny {
        padding-left: 10px;
        padding-right: 10px;
      }

      &-small-2x {
        padding-left: 15px;
        padding-right: 15px;
      }

      &-small {
        padding-left: 20px;
        padding-right: 20px;
      }

      &-medium {
        padding-left: 30px;
        padding-right: 30px;
      }

      &-big {
        padding-left: 35px;
        padding-right: 35px;
      }
    }

    &-y {
      &-small-2x {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      &-small {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      &-medium {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      &-big {
        padding-top: 25px;
        padding-bottom: 25px;
      }
    }
  }

  &__left, &__right {
    &--no-shrink {
      flex-shrink: 0;
    }
  }

  &__left {
    margin-right: 25px;
  }

  &__right {
    text-align: right;
  }

  &--custom {
    color: #fff;

    &__left {
      font-weight: 700;

      #{$icon-selector} {
        margin-right: 5px;
      }
    }
  }
}

.month-picker {
  @include flex(space-between);

  @include rwd(phone) {
    margin-bottom: 10px;
  }

  .button {
    font-size: 18px;

    &:first-child {
      padding-right: 2px;
    }

    &:last-child {
      padding-left: 2px;
    }
  }

  &__date {
    font-weight: 700;
    margin: 0 20px;
  }
}

.meals {
  @include flex(flex-end);

  @include rwd(phone) {
    justify-content: center;
  }

  &__meal {
    display: inline-block;
    margin-left: 15px;

    &:first-child {
      margin-left: 0;
    }

    #{$icon-selector} {
      margin-right: 3px;
      font-size: 14px;
    }

    @include rwd(large) {
      margin-left: 10px;
    }

    &--success {
      color: $success;
      font-weight: 700;
    }

    &--fail {
      color: $danger;
      font-weight: 700;
    }

    &--unknown {
      padding-left: 6px;
    }
  }
}

.account-balance {
  padding-bottom: 0 !important;

  @media screen and (min-width: 1440px) {
    padding-top: 0 !important;
  }
  @media screen and (min-width: 640px) and (max-width: 1200px) {
    padding-top: 0 !important;
  }

  &__box {
    @media screen and (min-width: 640px) and (max-width: 1200px) {
      @include flex(space-between, center, false, false, false);
      width: 100%;

      .page-box-title {
        margin-bottom: 0 !important;
      }
    }

    @include rwd(xlarge, true) {
      @include flex(space-between, center, false, false, false);
      width: 100%;

      .page-box-title {
        margin-bottom: 0 !important;
      }
    }
    &.h100{
      height: 100%;
    }
  }

  &__amount {
    @include flex(flex-end, center, false);

    @include rwd(phone) {
      justify-content: center;
    }

    &-text {
      @include flex(flex-start, center, false, false);

      @include rwd(phone) {
        flex-direction: column;
        align-items: flex-start;

        span {
          text-align: left;
        }
      }
      &.invoices-box{
        @include rwd(xlarge) {
          width: 100%;
          justify-content: center;
          align-items: center;
        }
      }
    }

    &-number {
      position: relative;
      overflow: hidden;
      padding: 20px 0;
      margin: 0 30px;
      white-space: nowrap;

      @include rwd(phone) {
        padding: 5px 0 20px 0;
        margin: 0 30px 0 0;
      }

      &:before {
        content: '';
        position: absolute;
        border-radius: 3px;
        height: 6px;
        bottom: -3px;
        left: 0;
        right: 0;
      }

      &--positive {
        &:before {
          content: '';
          background: $payment-plus;
        }
      }

      &--negative {
        &:before {
          content: '';
          background: $payment-minus;
        }
      }
    }
  }

  .button {
    @include rwd(phone) {
      margin-top: 5px;
    }
  }
}

.absolute-moving-container {
  position: absolute;
  white-space: nowrap;
  height: 28px;

  right: 16px;
  top: 14px;

  @include rwd(large) {
    right: 15px;
    top: 16px;
  }

  @include rwd(phone) {
    top: auto;
    bottom: 20px;
    right: 50%;
    transform: translateX(50%);
  }
}

.multiple-icons-with-text {
  @include flex(flex-start, flex-start, true, false, true);

  #{$icon-selector} {
    margin-right: 7px;
  }

  @include rwd(phone) {
    flex-direction: column;
  }

  &__icon {
    margin-right: 30px;

    @include rwd(phone) {
      margin-right: 0;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.accounts-grid {
  position: relative;

  &__account {
    margin: 0 15px 12.5px 0 !important;
    padding: 7.5px;
    border-radius: 4px;

    @include transition(background-color);

    @include on-event() {
      background-color: $theme-bg;
      cursor: pointer;
    }

    @include rwd(medium) {
      margin-right: 0 !important;
    }

    &--last {
      @include rwd(medium, true) {
        margin-bottom: 0 !important;
      }
    }
  }

  .av__name {
    margin-bottom: 2px;
  }

  .button[data-circle-button] {
    margin-top: 7.5px !important;
    margin-left: 7.5px !important;

    @include rwd(phone) {
      margin-bottom: 40px;
    }
  }
}

.balance-history {
  &--headers {
    color: $theme-gray;
    background: transparent !important;
    border: none !important;
    padding-bottom: 0;

    .balance-history__item-left, .balance-history__item-right {
      font-weight: 300 !important;
    }
  }

  &__item {
    @include flex(space-between, baseline, false);

    &--positive {
      @include transition(background-color);
      @extend .message--success;
      @include on-event() {
        background-color: rgba($success, .2);
      }
    }

    &--negative {
      @include transition(background-color);
      @extend .message--danger;
      @include on-event() {
        background-color: rgba($danger, .2);
      }
    }

    &-left {
      width: 25%;

      @include rwd(medium) {
        width: 50%;
      }

      &-onetimer{
        width: 50%;
      }
    }

    &-right {
      width: 75%;

      @include rwd(medium) {
        width: 50%;
      }
      &-onetimer{
        width: 50%;
      }
    }
  }

  .numbers-box {
    width: 100%;
    @include flex(flex-end, center, false);
    @extend .text--gray;

    &__number {
      width: 20%;
      flex-grow: 1;
      padding: 0 3px;
      text-align: right;
      white-space: nowrap;

      &:last-child {
        @include rwd(medium) {
          display: block;
        }
      }

      @include rwd(medium) {
        display: none;
        width: 100%;
        text-align: right;
      }
    }
  }
}

.kid-calendar {
  &-av-phone {
    margin-bottom: 25px;

    @include rwd(phone, up) {
      display: none;
    }

    .av__name {
      @extend %text-large;
    }
  }

  &__top {
    @include flex(space-between);

    @include rwd(phone, true) {
      margin-bottom: 5px;
    }

    @include rwd(phone) {
      justify-content: center;

      .av {
        display: none;
      }
    }
  }

  &__item {
    &-event {
      .event-box {
        &:last-child {
          margin-bottom: 0;
        }

        @include rwd(phone) {
          flex-direction: column;
          align-items: center;
        }

        &__left {
          flex-shrink: 0;
          @include rwd(phone) {
            margin-right: 0;
            margin-bottom: 5px;
            text-align: center;
          }
        }

        &__right {
          @extend %text-small;
          color: $theme-gray;

          @include rwd(phone) {
            text-align: center;
          }
        }

        &--custom {
          &__left {
            flex-shrink: 1;
          }

          &__right {
            flex-shrink: 0;

            .trash-container {
              @include flex(flex-start, baseline, false);

              &__hour {
                @include font(13, 15, 0);
                color: #fff;
                margin-right: 25px;
              }

              &__delete {
                .button__text {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }

    .badge-container {
      position: relative;
      margin-top: 10px;
      margin-bottom: 10px;

      @include rwd(phone) {
        @include flex(center);
      }

      &-margin {
        &-y {
          &-top {
            &-none {
              margin-top: 0;
            }
          }
        }
      }

      &--active {
        &:before {
          content: '';
          top: 50%;
          transform: translateY(-50%);
          height: 2px;
          position: absolute;
          display: block;
          background-color: $theme;
        }

        &-small {
          &:before {
            content: '';
            left: -16px;
            right: -16px;

            @include rwd(large) {
              left: -15px;
              right: -15px;
            }
          }
        }

        &-medium {
          &:before {
            content: '';
            left: -36px;
            right: -36px;

            @include rwd(large) {
              left: -15px;
              right: -15px;
            }
          }
        }
      }

      .badge {
        margin: 0;
        position: relative;
      }
    }
  }
}

.kid-access {
  &__row-name {
    margin-right: 20px;
    color: darken($gray-600, 10%);
    word-break: break-word;
  }

  &__buttons-container {
    @include flex();
    margin-top: 10px;

    .button {
      margin-right: 30px;
      margin-top: 10px;
    }
  }

  .event-box {
    .event-box__left {
      flex-shrink: 1;

      .button {
        margin-left: 10px;

        @include rwd(small) {
          margin-left: 5px;
        }
      }
    }

    @include rwd(phone) {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
}

.kid-additional-activities {
  .event-box {
    align-items: center;

    &__right {
      @include flex(flex-start, center, false);
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include rwd(phone) {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
}

.kid-contact {
  &-attendants-info {
    #{$icon-selector} {
      margin-right: 7px;
    }
  }

  &-attendants-code {
    .event-box {
      &:last-child {
        margin-bottom: 0;
      }

      @include rwd(phone) {
        flex-direction: column;
      }

      &__right {
        @include flex(flex-start, center, false, false, false);

        span {
          word-break: break-word;
        }

        .button {
          margin-left: 10px;
        }

        @include rwd(phone) {
          margin-top: 10px;
          text-align: left !important;
        }
      }
    }
  }
}

.kid-identities {
  .av {
    display: none;

    @include rwd(phone) {
      display: block;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__row {
    @include flex(flex-start, stretch, false);

    &:first-child {
      div {
        padding-top: 4px;
      }
    }

    &:last-child {
      div {
        padding-bottom: 4px;
      }
    }

    @include rwd(phone) {
      flex-direction: column;

      &:first-child {
        div {
          padding-top: 0;
        }
      }

      &:last-child {
        div {
          padding-bottom: 0;
        }

        .kid-identities__right {
          border-bottom: none;
          margin-bottom: 0;
        }
      }
    }
  }

  &__left {
    padding-bottom: 10px;
    margin-right: 20px;
    font-weight: 300;
    flex-shrink: 0;
    width: 15%;
    text-align: right;
    color: $gray-600;

    @include rwd(xlarge) {
      width: 20%;
    }

    @include rwd(phone) {
      width: auto;
      padding-bottom: 5px;
      text-align: left;
    }
  }

  &__right {
    padding-bottom: 10px;
    padding-left: 20px;
    font-weight: 500;
    border-left: 1px solid darken($gray-100, 5%);
    word-break: break-word;

    @include rwd(phone) {
      width: 100%;
      margin-bottom: 5px;
      padding-bottom: 0;
      padding-left: 0;
      border-left: none;
      border-bottom: 1px solid darken($gray-100, 5%);
    }

    &--last {
      @include rwd(phone) {
        margin-bottom: 0;
      }
    }
  }
}

.kid-panel {
  &__container {
    width: 100%;
  }

  @include flex(space-between, center, false, false, false);
  margin: 20px 0;

  &__prev {
    #{$icon-selector} {
      margin: 0 8px;
    }

    @include rwd(phone) {
      .button {
        margin-left: 8px;
      }
    }
  }

  &__next {
    #{$icon-selector} {
      margin: 0 8px;
    }

    @include rwd(phone) {
      .button {
        margin-right: 8px;
      }
    }
  }

  &__prev, &__next {
    a:first-child {
      display: none;
      @include rwd(phone) {
        display: flex;
      }
    }

    @include rwd(phone) {
      #{$icon-selector} {
        @include font(18, 18, 0);
      }
    }
  }

  &__active {
    padding: 0 20px;

    .av {
      &__details {
        padding-left: 15px;
      }
      &__name {
        padding-top: 8px;
        margin-bottom: 4px;

        @include rwd(phone) {
          margin-bottom: 8px;
        }
      }
    }
  }
}

.kid-profile {
  &__additional-navigation {
    .button__icon {
      margin-right: 10px;
      flex-shrink: 0;
    }

    .button__text {
      text-align: left;
    }
  }
}

.uploader {
  &__file {
    position: relative;

    &-left {
      @include flex(flex-start, flex-start, false);
      font-weight: 500;
    }

    &-icon {
      @include font(17, 18, 0);
      margin-right: 5px;
      color: $theme-gray;
    }

    &-disabled {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 100;
      background: rgba(#fff, 0.5);
    }
  }
}

.tabs-content {
  position: relative;
}

.sortable {
  &-foggy-background {
    opacity: 30%;
  }

  &-target {
    z-index: 1000;
  }
}

.outline {
  &-none {
    outline: none;
  }
}
