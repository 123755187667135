$block-button: '.button';

@mixin button-white-base {
  background-color: #fff;
  color: $theme-dark;
  @include transition(background-color);

  @include on-event() {
    background-color: $theme-bg;
    color: $theme-dark;
  }
}

@mixin button-text-gray-base {
  color: $gray-600;

  @include on-event() {
    color: $gray-900;
  }
}

// MIXINS SIZES
@mixin button-size-small {
  @include font(13, 15, 0);
  padding: 8px 15px;

  @at-root #{selector-unify(&, #{$block-button}--icon)} {
    width: 22px;
    height: 22px;
    padding: 0;
  }

  @at-root #{selector-unify(&, #{$block-button}--icon-fill)} {
    #{$block-button}__icon {
      width: 22px;
      height: 22px;
      padding: 0;

      #{$icon-selector} {
        font-size: 11px;
      }
    }
  }
}
@mixin button-size-smedium {
  @extend %text-base;
  padding: 10px 20px;

  @at-root #{selector-unify(&, #{$block-button}--icon)} {
    width: 28px;
    height: 28px;
    padding: 0;
  }

  @at-root #{selector-unify(&, #{$block-button}--icon-fill)} {
    #{$block-button}__icon {
      width: 28px;
      height: 28px;
      padding: 0;

      #{$icon-selector} {
        font-size: 14px;
      }
    }
  }
}
@mixin button-size-medium {
  @extend %text-base;
  padding: 12px 25px;

  @include rwd(small) {
    padding: 10px 22px;
  }

  @at-root #{selector-unify(&, #{$block-button}--icon)} {
    width: 35px;
    height: 35px;
    padding: 0;
  }

  @at-root #{selector-unify(&, #{$block-button}--icon-fill)} {
    #{$block-button}__icon {
      width: 35px;
      height: 35px;
      padding: 0;

      #{$icon-selector} {
        font-size: 15px;
      }
    }
  }
}
@mixin button-size-big {
  @include font(14, 12, 50);
  padding: 13px 25px;
  font-weight: 600;

  @at-root #{selector-unify(&, #{$block-button}--icon)} {
    width: 40px;
    height: 40px;
    padding: 0;
  }

  @at-root #{selector-unify(&, #{$block-button}--icon-fill)} {
    #{$block-button}__icon {
      width: 40px;
      height: 40px;
      padding: 0;

      #{$icon-selector} {
        font-size: 19px;
      }
    }
  }
}
@mixin button-size-large {
  @include font(18, 18, 0);
  padding: 12px 20px;
  // TODO: ???
}
@mixin button-size-huge {
  @include font(14, 14, 50);
  padding: 20px 40px;
  font-weight: 600;
  // TODO: ???
}
//

@mixin button-round {
  border-radius: 40px;
}

@mixin button-fill {
  background-color: $theme;
  border: 1px solid transparent;
  color: #fff;
  @include transitions(background-color, color);

  @include on-event() {
    border-color: transparent;
    background-color: $theme-light;
    color: $theme;
  }
}

@mixin icon-with-text-transparent {
  @at-root #{selector-unify(&, #{$block-button}--icon-with-text)} {
    @extend #{$block-button}--transparent;
  }
}

.button {
  display: inline-block;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  border: none;
  text-align: center;
  text-decoration: none;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  padding: 0;
  font-family: $font-family-redesign;

  // deprecated
  &--with-icon {
    @include flex(center, center, false, false, true);

    i, svg {
      flex-shrink: 0;
      margin-right: 5px;
    }
  }

  &--hidden {
    visibility: hidden;
    width: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &--loading {
    position: relative;
    overflow: hidden;
    pointer-events: none;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $theme;
      z-index: 3;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 4;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      width: 12px;
      height: 12px;
      background: #fff;
      border-radius: 50%;
      animation: loading-pulse 1s ease infinite;
    }
  }

  &--icon {
    @include flex(center, center, false);
    border-radius: 50%;
    font-weight: 500;
  }

  &--icon-fill {
    #{$block-button}__icon {
      @extend #{$block-button}--icon;
    }
  }

  // styles
  &--text {
    color: $theme-gray;
    font-weight: 400;
    @extend %text-base;
    @include link-hover($theme);
    background-color: transparent;

    &-blue {
      color: $theme;
      @include link-hover($theme-secondary);
    }
  }

  &--white {
    @include button-white-base;
    @include icon-with-text-transparent;

    @at-root #{selector-unify(&, #{$block-button}--icon-fill)} {
      #{$block-button}__icon {
        background-color: #fff;
        color: $theme-gray;
        @include transitions(background-color, color);
      }

      @include on-event() {
        #{$block-button}__icon {
          background-color: $theme;
          color: #fff;
        }
      }
    }

    &-phone {
      @include rwd(phone) {
        @include button-white-base;
      }
    }
  }

  &--darken-white {
    @include icon-with-text-transparent;

    @at-root #{selector-unify(&, #{$block-button}--icon-fill)} {
      #{$block-button}__icon {
        background-color: $gray-100;
        color: $theme-gray;
        @include transitions(background-color, color);
      }

      @include on-event() {
        #{$block-button}__icon {
          background-color: $theme;
          color: #fff;
        }
      }
    }

    &-phone {
      @include rwd(phone) {
        @include button-white-base;
      }
    }
  }

  &--fill {
    @include button-fill;

    @at-root #{selector-unify(&, #{$block-button}--icon-with-text)} {
      @extend #{$block-button}--transparent;
    }

    @at-root #{selector-unify(&, #{$block-button}--icon-fill)} {
      color: $theme;
      @include transition(color);

      #{$block-button}__icon {
        background-color: $theme;
        color: #fff;
        @include transitions(background-color, color);
      }

      @include on-event() {
        color: $theme-secondary;

        #{$block-button}__icon {
          background-color: $theme-secondary;
          color: #fff;
        }
      }
    }
  }

  &--fill-dark {
    background-color: #2D5CBF;
    border: 1px solid transparent;
    color: #fff;
    @include transition(background-color);

    @include on-event() {
      border-color: transparent;
      background-color: $theme-hover;
      color: #fff;
    }
  }

  &--green-lighten {
    @at-root #{selector-unify(&, #{$block-button}--icon-with-text)} {
      @extend #{$block-button}--transparent;
    }

    @at-root #{selector-unify(&, #{$block-button}--icon-fill)} {
      color: $redesign-green-color;
      @include transition(color);

      #{$block-button}__icon {
        background-color: $redesign-green-color;
        color: #fff;
        @include transitions(background-color, color);
      }

      @include on-event() {
        color: lighten($redesign-green-color, 10%);

        #{$block-button}__icon {
          background-color: lighten($redesign-green-color, 10%);
          color: #fff;
        }
      }
    }
  }

  &--theme-lighten {
    @at-root #{selector-unify(&, #{$block-button}--icon-with-text)} {
      @extend #{$block-button}--transparent;
    }

    @at-root #{selector-unify(&, #{$block-button}--icon-fill)} {
      color: $theme;
      @include transition(color);

      #{$block-button}__icon {
        background-color: $theme;
        color: #fff;
        @include transitions(background-color, color);
      }

      @include on-event() {
        color: lighten($theme, 10%);

        #{$block-button}__icon {
          background-color: lighten($theme, 10%);
          color: #fff;
        }
      }
    }
  }

  &--light {
    background-color: $theme-bg;
    border: 1px solid transparent;
    color: $theme-dark;
    @include transition(background-color);

    @include on-event() {
      border-color: transparent;
      background-color: $theme;
      color: #fff;
    }
  }

  &--light-gray {
    background-color: $theme-bg;
    border: 1px solid transparent;
    color: $theme-dark;
    @include transition(background-color);

    @include on-event() {
      border-color: transparent;
      background-color: #dfe3e5;
      color: $theme-dark;
    }
  }

  &--darken-light {
    background-color: $gray-200;
    border: 1px solid transparent;
    color: $theme-dark;
    @include transition(background-color);

    @include on-event() {
      border-color: transparent;
      background-color: $theme;
      color: #fff;
    }
  }


  &--fill-secondary {
    background-color: $theme-secondary;
    border: 1px solid transparent;
    color: #fff;
    @include transition(background-color);
    @include on-event() {
      border-color: transparent;
      background-color: $theme-secondary-hover;
      color: #fff;
    }
  }

  &--fill-opacity {
    background-color: rgba(0, 0, 0, .1);
    color: #fff;
  }

  &--outline {
    background-color: transparent;
    color: $theme;
    border: 1px solid $theme;
    transition: background-color .2s, color .2s;
    @include on-event() {
      background-color: $theme;
      color: #fff;
    }
  }

  &--success-basic {
    @extend #{$block-button}--round;
    @extend #{$block-button}--bold;
    background-color: $success;
    color: #fff;
    @include transition(background-color);
    @include on-event() {
      background-color: darken($success, 5%);
      color: #fff;
    }
  }

  &--success {
    @extend #{$block-button}--round;
    @extend #{$block-button}--medium;
    @extend #{$block-button}--bold;
    background-color: $success;
    color: #fff;
    @include transition(background-color);
    @include on-event() {
      background-color: darken($success, 5%);
      color: #fff;
    }
  }

  &--danger {
    @extend #{$block-button}--round;
    @extend #{$block-button}--medium;
    @extend #{$block-button}--bold;
    background-color: $danger;
    color: #fff;
    @include transition(background-color);
    @include on-event() {
      background-color: darken($danger, 5%);
      color: #fff;
    }
  }

  &--gray {
    background-color: $gray-200;
    color: #111;
    @include transition(background-color);
    @include on-event() {
      background-color: darken($gray-200, 5%);
    }
  }

  &--bordered {
    @extend #{$block-button}--round;
    @extend #{$block-button}--bold;
    background-color: #fff;
    color: $text-muted;
    @include transition(background-color);
    border: 1px solid $gray-300;

    &.button--loading {
      &:before {
        color: $text-muted;
      }
      &:after {
        background: $gray-200;
      }
    }

    @include on-event() {
      background-color: $gray-200;
    }
  }

  // styles && fonts

  // TODO: transparent && blue as one text button
  &--transparent {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
  }

  &--transparent-opacity {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;

    @include transition(opacity);

    @include on-event() {
      opacity: 0.7;
    }
  }

  &--text-gray {
    @include button-text-gray-base;
  }

  &--blue {
    color: $theme;
    @include on-event() {
      color: $theme-hover;
    }
  }

  &--color {
    @include transition(color);

    &-gray {
      color: $theme-gray;

      @include on-event() {
        color: $theme-dark;
      }
    }
  }

  &--round {
    @include button-round;

    &-tiny {
      border-radius: 5px;
    }
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--bold {
    font-weight: 600;
  }

  // Sizes
  &--small {
    @include button-size-small;
  }

  &--smedium {
    @include button-size-smedium;
  }

  &--medium {
    @include button-size-medium;
  }

  &--big {
    @include button-size-big;
  }

  &--large {
    @include button-size-large;
  }

  &--huge {
    @include button-size-huge;
  }
  //

  &--icon {
    //  deprecated -text
    &-text {
      @include flex(flex-start, center, false, false, true);

      #{$block-button}__icon {
        @extend #{$block-button}--icon;
        width: 24px;
        height: 24px;
        padding: 0;
        font-size: 16px;
        flex-shrink: 0;

        #{$icon-selector} {
          font-size: 19px;
        }
      }

      #{$block-button}__text {
        @extend %text-base;
        font-weight: 700;
        color: $theme;
        padding-left: 8px;

        @include on-event() {
          color: $theme;
        }
      }

      @include on-event() {
        #{$block-button}__text {
          color: $theme-secondary;
        }
      }
    }

    &-radio-text {
      @extend #{$block-button}--icon-text;

      #{$block-button}__icon {
        @extend #{$block-button}--icon;
        width: 24px;
        height: 24px;
        padding: 0;
        flex-shrink: 0;

        #{$icon-selector} {
          font-size: 13px;
          color: $white;
        }
      }

      #{$block-button}__text {
        line-height: 1;
      }
    }

    // TODO: Remove this - eg. --small
    &-big {
      width: 60px;
      height: 60px;

      i {
        font-size: 24px;
      }
    }

    &-big-text {
      i {
        font-size: 26px;
      }
    }
  }

  //  Use this instead icon-text
  &--icon-with-text {
    @include flex(flex-start, center, false, false, true);

    #{$block-button}__text {
      padding-left: 10px;
    }

    &-tiny-gap {
      #{$block-button}__text {
        padding-left: 2px;
      }
    }

    &-reversed {
      #{$block-button}__text {
        padding-left: 0;
        padding-right: 10px;
      }

      &-tiny-gap {
        #{$block-button}__text {
          padding-left: 0;
          padding-right: 2px;
        }
      }
    }
  }

  //  Common buttons
  &--theme {
    @extend #{$block-button}--fill;
    @extend #{$block-button}--round;
    @extend #{$block-button}--medium;
    @extend #{$block-button}--bold;
  }

  &--theme-secondary {
    @extend #{$block-button}--fill-secondary;
    @extend #{$block-button}--round;
    @extend #{$block-button}--medium;
    @extend #{$block-button}--bold;
  }

  &--link {
    @extend #{$block-button}--text;
    @extend #{$block-button}--text-blue;
    @extend #{$block-button}--transparent;
  }

  &--type {
    &-icon {
      @extend #{$block-button}--white;
      @extend #{$block-button}--icon;
      @extend #{$block-button}--medium;
    }
  }

  &--remove {
    @extend #{$block-button}--icon;
    @extend #{$block-button}--big;
    @extend #{$block-button}--danger;
  }

  &--add {
    @extend #{$block-button}--with-icon;
    @extend #{$block-button}--fill;
    @extend #{$block-button}--round;
    @extend #{$block-button}--medium;
    @extend #{$block-button}--bold;
  }

  &--add-small {
    @extend #{$block-button}--with-icon;
    @extend #{$block-button}--fill;
    @extend #{$block-button}--round;
    @extend #{$block-button}--small;
    @extend #{$block-button}--bold;
  }

  &--icon-action {
    @extend #{$block-button}--big;
    @extend #{$block-button}--transparent;
    @extend #{$block-button}--icon;
    @extend #{$block-button}--color;
    @extend #{$block-button}--color-gray;
  }

  &--paging {
    @extend #{$block-button}--transparent;
    width: 28px;
    height: 28px;
    margin: 0 3px;
    color: $gray-600;

    #{$icon-selector} {
      font-size: 12px;
      color: $gray-700;
    }

    @include transition(background-color);
    &:hover {
      background-color: $gray-100;
    }

    @include rwd(phone) {
      margin: 0 1px;
    }

    &-active {
      background: $theme-bg !important;
      border-radius: 4px;
      color: $text;
      font-weight: 600;
    }

    &-disabled {
      background: transparent !important;
      color: $gray-300;
      cursor: default;

      #{$icon-selector} {
        color: $gray-300;
      }
    }
  }

  &--count {
    overflow: visible;

    #{$block-button}__count {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #fff;
      color: $dark;
      position: absolute;
      right: -2px;
      top: -2px;
      z-index: 1;
      pointer-events: none;
      text-align: center;
      @include font(11, 11, 0);
      display: inline-block;
      font-weight: 500;
      @include flex(center, center, false);
    }
  }

  &--no-events {
    pointer-events: none;
  }

  &--events {
    &-opacity {
      @include transition(opacity);
      @include on-event() {
        opacity: .65;
      }
    }
  }

  &__tiny-info {
    color: $theme-gray-lighter;
    display: block;
    text-align: left;
    font-size: 11px;
  }
}

@keyframes loading-pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -50%) scale(.3);
    opacity: .3;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

.buttons {
  @include flex(flex-start, center, true);
  margin-left: -10px;
  margin-right: -10px;

  .button {
    margin: 5px 10px;
  }

  &--space {
    &-small {
      margin-left: -5px;
      margin-right: -5px;

      .button {
        margin: 5px;
      }
    }
  }

  &--center {
    justify-content: center;

    &-mobile {
      @include rwd(medium) {
        justify-content: center;
      }
    }
  }

  &--justify {
    justify-content: space-between;
  }

  &--margin-top {
    margin-top: 20px;
  }

  &--margin-bottom {
    margin-bottom: 20px;
  }

  &--mask-before {
    position: relative;

    &:before {
      content: '';
      left: 0;
      right: 0;
      bottom: 100%;
      display: block;
      position: absolute;
      box-shadow: 0 0 150px 100px #fff;
    }
  }

  &--relative {
    position: relative;
  }
}

// global actions on buttons
[data-show-hidden-content] {

}

[data-hidden-content] {
  display: none;

  &[data-hidden-content-active] {
    display: block;
  }
}
