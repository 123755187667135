.special-checkboxes {
  &.relative{
    position: relative;
  }
  & > .input-helper {
    & > div {
      @include flex(flex-start, stretch, true);
      margin-left: -5px;
      margin-right: -5px;

      &:nth-last-child(2) {
        padding-top: 22px;
      }
    }

    & > label {
      @include font(16, 19, 0);
      font-weight: 700;
      color: $text !important;
    }
  }

  .radio, .checkbox, .radio-inline {
    padding: 5px;
    width: 33.33%;

    @include rwd(small) {
      width: 100%;
    }

    label {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;

      span {
        background-color: $theme-bg;
        border-radius: 5px;
        position: relative;
        padding: 40px 15px 10px 15px;
        width: 100%;
        height: 100%;
        text-align: center;
        cursor: pointer;
        @include font(14, 16, 0);
        display: block;

        @include rwd(small) {
          padding: 15px 10px 15px 45px;
          text-align: left;
        }

        &:before {
          content: '';
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 10px;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: #fff;
          border: none;

          @include rwd(small) {
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        &:after {
          @include fal-icon(f00c);
          border-radius: 0;
          transition: none;
          width: auto;
          height: auto;
          background: none;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 14px;
          font-size: 15px;
          color: #fff;

          @include rwd(small) {
            left: 16px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      input:checked + span {
        background-color: rgba($success, .1);

        &:before {
          background-color: $success;
        }
      }
      &.danger{
        input:checked + span {
          @apply bg-red-200;

          &:before {
            @apply bg-red-600;
          }
        }
      }

      &.neutral{
        input:checked + span {
          @apply bg-gray-200;

          &:before {
            @apply bg-gray-400;
          }
        }
      }
    }
  }
  &.column-2 {
    .radio {
      width: 50%;
      @include rwd(small) {
        width: 100%;
      }
    }
  }

  .radio-inline{
      width: auto;
      display: inline-block;
      padding: 8px 0px;
      @include rwd(tablet) {
        width: 50%;
      }
      label{
        span{
          padding: 15px 40px 12px 60px;
          text-align: left;
          @include rwd(small) {
            padding-left: 45px;
          }
          &:before{
            left: 30px;
            @include rwd(small) {
              left: 10px;
            }
          }
          &:after{
            left: 30px;
            @include rwd(small) {
              left: 16px;
            }
          }
        }
      }
  }
}