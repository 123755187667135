@mixin font-face($style-name, $filename, $directory-family, $weight) {
  $filepath: "../fonts/" + $directory-family + "/" + $filename;
  @font-face {
    font-family: "#{$style-name}";
    src: url($filepath + ".ttf") format('truetype');
    font-weight: #{$weight};
    font-style: normal;
  }
}

@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

@mixin font($fontsize, $lineheight, $letter-spacing:0) {
  font-size: $fontsize + px;
  line-height: ($lineheight / $fontsize);
  @if $letter-spacing !=0 {
    letter-spacing: ($letter-spacing / 1000) * 1em;
  }
}

@mixin fontauto($fontsize, $letter-spacing:0, $lh:true) {
  font-size: $fontsize + px;
  @if $lh {
    line-height: 1.2;
  }
  @if $letter-spacing !=0 {
    letter-spacing: ($letter-spacing / 1000) * 1em;
  }
}

@mixin letterspacing($letter-spacing) {
  @if $letter-spacing != 0 {
    letter-spacing: ($letter-spacing / 1000) * 1em;
  }
}

@mixin reset($type: "list") {
  @if $type=="number" {
    appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  } @else if $type=="select" {
    appearance: none;
    &::-ms-expand {
      display: none;
    }
  } @else if $type=="button" {
    appearance: none;
    background-color: transparent;
    box-shadow: none;
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
  } @else if $type=="absolute" {
    left: auto;
    top: auto;
    right: auto;
    bottom: auto;
    position: relative;
  } @else {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

@mixin transition($what: color, $time: .1s, $type: linear) {
  transition: $what $time $type;
}

@mixin transitions($args...) {
  $result: ();
  @for $i from 1 through length($args) {
    $prop: nth($args, $i);
    $result: append($result, $prop .1s linear);
    @if $i != length($args) {
      $result: append($result, unquote($string: ","));
    }
  }
  transition: $result;
}

@mixin link-hover($color: $theme-hover, $active: false) {
  @include transition(color);

  @include on-event() {
    color: $color;
  }

  @if $active == true {
    &.active {
      color: $color;
    }
  }
}

@mixin hover($color: $theme-hover, $background: $secondary, $active: false) {
  @include on-event() {
    color: $color;
    background: $background;
  }
  @if $active == true {
    &.active {
      color: $color;
      background: $background;
    }
  }
}

@mixin faIcon($style: false, $icon: false) {
  @if $style != false and $icon != false {
    @extend .#{$style};
    @extend .fa-#{$icon};
  }
}

@mixin font-icon($code) {
  font-family: "icons";
  content: "#{$code}";
}

@mixin background($size: cover, $position: center) {
  background-size: $size;
  background-repeat: no-repeat;
  background-position: $position;
}

@mixin beforeabsoulte($left: 0, $top: 0, $right: 0, $bottom: 0) {
  content: '';
  position: absolute;
  left: $left;
  top: $top;
  right: $right;
  bottom: $bottom;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    opacity: 1;
    @content;
  }
  &::-moz-placeholder {
    opacity: 1;
    @content;
  }
  &:-ms-input-placeholder {
    opacity: 1;
    @content;
  }
  &:-moz-placeholder {
    opacity: 1;
    @content;
  }
}

@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin flex($ha: flex-start, $va: center, $wrap: true, $column: false, $inline: false) {
  @if $inline {
    display: inline-flex;
  } @else {
    display: flex;
  }
  justify-content: $ha;
  align-items: $va;
  @if $wrap {
    flex-wrap: wrap;
  }
  @if $column {
    flex-direction: column;
  }
}

@mixin rwd($width, $up:false) {
  @if $width == print {
    @media print {
      @content;
    }
  } @else {
    @if map-has-key($breakpoints, $width) {
      @if $up {
        @media screen and (min-width: add-unit(strip-unit(map-get($breakpoints, $width)))) {
          @content;
        }
      } @else {
        @media screen and (max-width: add-unit(strip-unit( map-get($breakpoints, $width) ) - 1)) {
          @content;
        }
      }
    } @else {
      @if $up {
        @media screen and (min-width: add-unit(strip-unit($width))) {
          @content;
        }
      } @else {
        @media screen and (max-width: add-unit(strip-unit( $width ) - 1)) {
          @content;
        }
      }
    }
  }
}


// required for bootstrap v4
// stylelint-disable indentation
@mixin hover {
  // TODO: re-enable along with mq4-hover-shim
  //  @if $enable-hover-media-query {
  //    // See Media Queries Level 4: https://drafts.csswg.org/mediaqueries/#hover
  //    // Currently shimmed by https://github.com/twbs/mq4-hover-shim
  //    @media (hover: hover) {
  //      &:hover { @content }
  //    }
  //  }
  //  @else {
  &:hover {
    @content;
  }
  //  }
}

@mixin hover-focus {
  @if $enable-hover-media-query {
    &:focus {
      @content;
    }
    @include hover {
      @content;
    }
  } @else {
    &:focus,
    &:hover {
      @content;
    }
  }
}

@mixin noselect() {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin fal-icon($unicode) {
  font-family: "Font Awesome 5 Pro";
  content: unicode(#{$unicode});
  font-weight: 300;
}