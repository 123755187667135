.main-content {
  //margin-left: $sidebar-width;
  width: 100%;
  //display: flex;
  //max-width: calc(100% - #{$sidebar-width});

  // redesign
  margin-left: 0;
  max-width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  flex-grow: 1;

  .content {
    width: 100%;
    padding: 0 60px 20px 60px;
    // Przez overflow nie działa position:sticky, TODO: sprawdzić czy jest potrzebne
    //overflow: hidden;
    position: relative;
    //height: 100%;

    @include rwd(tablet) {
      height: 100%;
      height: calc(100% - #{$mobile-web-header-height});
    }

    @include rwd(large) {
      padding: 0 30px 30px 30px;
    }

    @include rwd(tablet) {
      padding: 20px 20px 124px 20px;
    }

    @include rwd(small) {
      padding: 10px 10px 124px 10px;
    }

    &--full {
      padding: 0 !important;
      margin: 0 !important;
    }

    .messages-view & {
      background-color: $white;
    }
  }
}
