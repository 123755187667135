%text-base {
  @include font(14, 17, 0);
}

%text-tiny {
  @include font(11, 13, 0);
}

%text-small {
  @include font(12, 14, 0);
}

%text-medium {
  @include font(13, 15, 0);
}

%text-large {
  @include font(16, 19, 0);
}

%text-huge {
  @include font(24, 28, 0)
}

.text {
  @extend %text-base;

  // sizes
  &--tiny {
    @extend %text-tiny;
  }

  &--small {
    @extend %text-small;
  }

  &--medium {
    @extend %text-medium;
  }

  &--tiny {
    @extend %text-tiny;
  }

  &--large {
    @extend %text-large;
  }

  &--huge {
    @extend %text-huge;
  }

  // colors
  &--black {
    color: $black;
  }

  &--dark {
    color: $theme-dark;
  }

  &--white {
    color: $white;
  }

  &--gray {
    color: $theme-gray;
  }

  &--theme, &--primary {
    color: $theme;
  }

  &--danger {
    color: $danger;
  }

  &--warning {
    color: $warning;
  }

  &--success {
    color: $success;
  }

  // deprecated
  &--bold {
    font-weight: 700;
  }
  &--super-bold {
    font-weight: 800;
  }

  &--fw {
    &-light {
      font-weight: 300;
    }

    &-normal {
      font-weight: 400;
    }

    &-medium {
      font-weight: 500;
    }

    &-semi-bold {
      font-weight: 600;
    }

    &-bold {
      font-weight: 700;
    }

    &-super-bold {
      font-weight: 800;
    }
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--left {
    text-align: left;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--margin {
    &-y {
      &-bottom {
        margin-bottom: 16px;
      }
    }
  }

  a {
    color: $theme;
    @include link-hover($theme-hover);
  }
}
