
.ai {
  &__input_button {
    &:before {
      content: "";
      position: absolute;

      background-color: transparent;
      bottom: 2px;
      left: -12px;
      height: 6px;
      width: 12px;
      border-bottom-right-radius: 99px;
      box-shadow: 6px 0 0 0 var(--tw-shadow-color);
    }

    &:after {
      content: "";
      position: absolute;
      background-color: $primary;
      right: 0;
      bottom: -6px;
      width: 2px;
      height: 6px;
    }
  }
}
