.tooltip {
  position: relative;

  @include rwd(tablet) {
    position: static;
  }

  &:before {
    @apply content-[attr(data-tooltip)] absolute text-sm font-normal text-gray-700;
    width: max-content;
    padding: 4px 12px;
    border-radius: 8px;
    background: white;
    border: 1px solid $gray-300;
    text-align: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    opacity: 0;
    visibility: hidden;
    transition: .3s opacity;
  }

  &__mobile_full {
    &:before {
      @include rwd(tablet) {
        bottom: unset !important;
        width: auto;
        transform: translate(0%, 100%) !important;
        margin-bottom: 0 !important;
        margin-top: 8px !important;
      }
    }
  }

  &:hover:before {
    display: block;
    opacity: 1;
    visibility: visible;
    z-index: 9;
  }

  &__top:before {
    bottom: 100%;
    margin-bottom: 8px;
  }

  &__align-center:before {
    left: 50%;
    transform: translateX(-50%);
  }

  &__align-left:before {
    left: 0px;
  }
}

