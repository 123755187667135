@import "@fancyapps/ui/dist/fancybox/fancybox.css";

:root {
    /* Override default value 1 */
    --fancybox-opacity: 0.85 !important;
}

.fancybox {
    &__backdrop {
        /* Override default value 1 */
        opacity: 0.85 !important;
    }

    &__slide {
        &.has-close-btn {
            padding-top: 4px !important;
        }
    }

    &__gallery {
        .fancybox__backdrop {
            background: rgba(24,24,27,1) !important;
            opacity: 1 !important;
        }
    }

    &__container {
        &.is-compact .has-html5video .fancybox__content {
            @include rwd(phone) {
                width: 100% !important;
                height: calc(100% - 120px) !important;
            }
        }
    }
}
