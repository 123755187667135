:root {
  --sait: env(safe-area-inset-top, 0);
  --sair: env(safe-area-inset-right, 0);
  --saib: env(safe-area-inset-bottom, 0);
  --sail: env(safe-area-inset-left, 0);

  // litepicker
  --litepickerMonthWidth: calc(var(--litepickerDayWidth) * 7); // 7 days
  --litepickerDayWidth: 37px;

  --litepickerDayColorHover: #{$theme};
  --litepickerDayIsTodayColor: #{$theme};
  --litepickerDayIsInRange: #f0f5ff;
  --litepickerDayIsBookedColor: #{$theme-secondary};
  --litepickerDayIsBookedBg: #{$theme-secondary}; // custom variable
  --litepickerDayIsStartBg: #{$theme};
  --litepickerDayIsEndBg: #{$theme};
  --litepickerHighlightedDayColor:  #{$theme-secondary};
  --litepickerHighlightedDayBg: #fff8ef;
}

// media hack for litepicker variables
@media (min-width: 481px) and (max-width: 579px) {
  :root {
    --litepickerDayWidth: #{percentage(1 / 7) - 0.01};
  }
}

html, body {
  min-height: 100%;
}

body {
  touch-action: manipulation;
  font-family: $font-family-redesign;
  margin: 0;
  padding: 0;
  color: $body-font-color;
  font-weight: $global-weight-normal;
  background-color: $theme-bg;

  &.overflow {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }

  &.overflow-mobile {
    @include rwd(tablet) {
      overflow: hidden;
      position: fixed;
      width: 100%;
      height: 100%;
    }
  }

  .body-locked {
    display: none;
  }

  &.full-content-view {
    background-color: #fff;
  }

  &.locked {
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: fixed;
    //cursor: pointer; // fix ios iPhone and iPad click - if element havent got cursor point - event click not working

    .body-locked {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(#141D2D, .50);
      // na panelu placówki tekst "już wkrótce" był większym z-indem niż maska locked
      z-index: 11; // fix switcher on presence list [mobile]
    }
  }

  &.locked-bottom-modal {
    overflow: hidden;

    .body-locked {
      display: block;
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(#141D2D, .50);
      z-index: 11;
    }
  }

  @at-root .messages-view .body-locked {
    top: 0 !important;
  }


  //&.mobile-app * {
  //  transition: none !important;
  //}

}

* {
  box-sizing: border-box;
}

button {
  // TODO: Remove after redesign all buttons
  @include on-event() {
    outline: none !important;
  }
}

a {
  text-decoration: none;
  position: relative;
  display: inline-block;
  @extend %text-base;
  color: $theme;
  @include link-hover($theme-hover);
}

.base-gradient {
  background: $primary;
  background: -moz-linear-gradient(left, $primary 0%, $primary-hover 100%);
  background: -webkit-linear-gradient(left, $primary 0%, $primary-hover 100%);
  background: linear-gradient(to right, $primary 0%, $primary-hover 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$primary}', endColorstr='#{$primary-hover}', GradientType=1);
}

.chart-gradient {
  background: rgb(214, 0, 0);
  background: -moz-linear-gradient(90deg, rgba(214, 0, 0, 1) 0%, rgba(235, 110, 3, 1) 20%, rgba(145, 149, 99, 1) 60%, rgba(84, 181, 0, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(214, 0, 0, 1) 0%, rgba(235, 110, 3, 1) 20%, rgba(145, 149, 99, 1) 60%, rgba(84, 181, 0, 1) 100%);
  background: linear-gradient(90deg, rgba(214, 0, 0, 1) 0%, rgba(235, 110, 3, 1) 20%, rgba(145, 149, 99, 1) 60%, rgba(84, 181, 0, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d60000", endColorstr="#54b500", GradientType=1);
}

.mobile-compensate-margin {
  @include rwd(phone) {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    width: auto;
  }
  @include rwd(small) {
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.pull-to-refresh__control {
  display: none;
}

.empty {
  color: $gray-400;
  font-style: italic;
}

.second-link {
  color: $secondary;

  &:hover {
    color: $primary;
  }
}

%scrollbar-style {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #f7faff;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f7faff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, .1);
    background-color: $primary;
  }
}

.payment {
  &--plus {
    color: $payment-plus;
  }

  &--minus {
    color: $payment-minus;
  }

  &--neutral {
    color: $payment-neutral;
  }
}

.status {
  &--present {
    color: $status_present;
  }

  &--absent {
    color: $status_absent;
  }

  &--reported {
    color: $status_reported;
  }

  &--empty {
    color: $status_empty;
  }
}

.button-wrapper {
  text-align: center;
  margin-top: 40px;

  @include rwd(phone) {
    margin-top: 30px;
  }
}

.app-version-info {
  margin-top: 30px;
}

.messages-view {
  #intercom-css-container, #intercom-container, .intercom-lightweight-app {
    display: none !important;
  }
}

.intercom-lightweight-app {
  z-index: 8 !important;
}

#messages {
  @include rwd(tablet) {
    position: relative;
    z-index: 11;
  }
}

.worker-calendar {
  .fc-daygrid-event-dot {
    @include rwd(phone) {
      display: none;
    }
  }

  .fc-daygrid-event {
    @include rwd(phone) {
      flex-wrap: wrap;
    }
  }

  .fc-direction-ltr .fc-daygrid-event .fc-event-time {
    @include rwd(phone) {
      font-size: 11px;
    }
  }

  .fc-daygrid-dot-event .fc-event-title {
    font-size: 14px;
  }

  &--event-hover {
    .fc-day:not(.fc-day-other):hover {
      background: #f3f3f3;
      cursor: pointer;
    }
  }
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  width: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  border: 0 !important;
}

.privilege-form {
  padding-top: 0 !important;
  margin-top: -20px !important;

  .checkbox {
    span {
      font-weight: bold !important;
      color: #333;
      font-size: 14px !important;
    }
  }
}

.loading-bar {
  display: block;
  position: fixed;
  z-index: 100;
  height: 3px;
  top: 0;
  left: 0;
  width: 0;
  background: $theme;
  pointer-events: none;

  &--leave {
    @include transition(width, .1s, ease-out);
    width: 25%;
  }

  &--entry {
    @include transition(width, .1s, ease-in);
    width: 100%;
  }

  &--hidden {
    display: none;
  }
}

.block {
  display: block;
  width: 100%;
}

.modal-loader {
  margin: 0 30px;
  text-align: center;

  .fa {
    font-size: 30px;
    color: $theme;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
  }

  &__desc {
    color: $gray-700;
    font-size: 13px;
  }
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gap-bottom{
  margin-bottom: 50px;
}
