.popup-table-info {
  max-width: 600px;

  &__child {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .avatar {
      margin-right: 10px;
    }
    h2 {
      margin-bottom: 0;
      @include rwd(small) {
        @include font(16, 20, 0);
      }
    }
    &-group {
      font-size: rem-calc(12);
      color: $secondary;
      margin-top: -2px;
      font-weight: 300;
    }

    &--with-nav {
      display: block;
      padding-left: 50px;
      padding-right: 50px;
      position: relative;
      width: 100%;
      text-align: center;

      .button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      [data-fancybox-prev], [data-fancybox-next] {
        &.hidden {
          @include transition(opacity);
          opacity: 0;
          pointer-events: none;
        }
      }

      [data-fancybox-prev] {
        left: 0;
      }

      [data-fancybox-next] {
        right: 0;
      }

      .avatar {
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
      }

      .popup-table-info__child-name {
        width: 100%;
        text-align: center;
      }
    }
  }
  &__table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    td {
      padding: 5px 10px;
      vertical-align: top;
      @include rwd(small) {
        &, * {
          font-size: rem-calc(12);
        }
        b {
          display: block;
        }
        padding: 5px;
      }
      &:nth-child(2) {
        font-weight: 600;
      }
    }
    tr {
      &:not(:first-of-type) {
        td {
          border-top: 1px solid #eee;
        }
      }
      &:nth-last-child(2) {
        td {
          padding-bottom: 10px;
        }
      }
    }
    td:first-of-type {
      width: 60%;
      //text-align: right;s
    }
    td:last-of-type {
      text-align: right;
    }
  }
  &__paid {
    td {
      &:nth-child(2) {
        color: $success;
      }
    }
  }
  &__sum {
    td {
      padding-top: 16px;
      padding-bottom: 10px;
    }
  }
  &__group {
    background: #f8f9fd;
    td {
      border-top: none !important;
    }
  }
  &__to-pay {
    background: #f8f9fd;
    color: $primary;
    padding: 10px;
    margin-top: 20px;
    border-radius: 3px;
    font-size: rem-calc(12);
    strong {
      font-size: rem-calc(14);
    }
    span {
      display: inline-block;
      margin: 0 5px;
    }
    td {
      border-top: none !important;
      padding: 10px;
      &:nth-child(1) {
        #{$icon-selector} {
          margin-top: -2px;
          font-size: rem-calc(20);
          @include rwd(349px) {
            display: none;
          }
        }
        span {
          @include rwd(349px) {
            margin: 0;
          }
        }
      }
      &:nth-child(2) {
        font-size: rem-calc(14);
      }
    }
  }
  &__note {
    td {
      &:nth-child(2) {
        font-weight: 400;
        color: $secondary;
      }
    }
  }
  &__actions {
    margin-top: 10px;
    position: relative;
    min-height: 40px;
    @include rwd(small) {
      padding-top: 40px;
    }
    &--no-absolute {
      @include rwd(small) {
        padding-top: 0;
      }
    }
    &:after {
      content: '';
      display: block;
      clear: both;
    }
    .btn {
      @include rwd(phone) {
        span {
          display: none;
        }
      }
    }
    .btn--prev {
      float: left;
    }
    .btn--next {
      float: right;
    }
    &-middle {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;

      @include rwd(small) {
        width: 100%;
        text-align: center;
      }
    }
    @include rwd(phone) {
      .settlement__print {
        display: none;
      }
      .btn {
        &:first-letter {
          text-transform: uppercase;
        }
      }
    }
    @include rwd(small) {
      .btn--prev, .btn--next {
        margin-top: 3px;
      }
    }
    @include rwd(340px) {
      .btn--prev, .btn--next {
        margin-top: 3px;
      }
    }
  }
  &__head {
    text-transform: uppercase;
    font-size: 11px;
    color: $primary;
    font-weight: bold;
    text-align: left !important;
    padding-top: 15px !important;
    border-top: none !important;
  }
  &__history {
    &--button {
      margin-top: 10px;
      text-align: center;
    }
    &--list {
      margin-top: 0.75rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    &--listitem {
      display: flex;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
      width: 100%;
      padding: 0.5rem;
      text-align: left;
      color: #4b5563;
      background-color: #f1f5f9;
      span{
        padding-right: 0.75rem;
        &.whitespace-nowrap{
          white-space: nowrap;
        }
      }
    }
  }
}

.balance-positive {
  color: $success;
}

.balance-negative {
  color: $danger;
}
