@function unicode($str) {
  @return unquote("\"") + unquote(str-insert($str, "\\", 1)) + unquote("\"");
}

@function strip-unit($number) {
  @return $number / ($number * 0 + 1);
}

@function add-unit($data, $unit: "px") {
  @return $data + $unit;
}

@function convert-to-rem($value, $base-value: $global-font-size) {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if ($value == 0rem) {
    $value: 0;
  }
  @return $value;
}

@function rem-calc($values, $base-value: $global-font-size) {
  $max: length($values);

  @if $max == 1 {
    @return convert-to-rem(nth($values, 1), $base-value);
  }

  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $remValues;
}

@function vw-calc($value, $customBase: true) {
  $base: if($customBase, $global-full-width, 1000);
  $vw-context: strip-unit(($base*.01) * 1px);
  @return (strip-unit($value)/$vw-context) * 1vw;
}