.popup {
  &__content {
    min-width: 600px;
    max-width: 50% !important;
    padding: 24px !important;
    //overflow: hidden !important;
    cursor: auto !important;

    @include rwd(medium) {
      max-width: 100% !important;
    }

    @include rwd(phone) {
      min-width: 400px;
    }
    @include rwd(small) {
      min-width: 100px;
    }

    &--fixed-width {
      width: 600px;
      @include rwd(phone) {
        width: 90vw;
      }
    }

    .fancybox-close-small {
      width: 40px;
      height: 40px;
      padding: 10px !important;
      top: 0px !important;
      right: 0px !important;

      svg {
        margin-top: 0 !important;

        path {
          fill: #fff;
          color: #fff;
        }
      }

      &:after {
        top: 5px;
        right: 3px;
        color: #fff;
      }
    }

    form {
      margin-top: 23px;
    }
  }

  &__title {
    text-align: left;
    @include font(16, 20, 0);
    font-weight: 500;
    padding: 10px 40px 10px 10px;
    margin-bottom: 10px;
    background-color: $primary;
    color: #fff;
    margin: -24px -24px 10px -24px; // fancybox default padding
    @include rwd(phone) {
      @include font(15, 18, 0);
      margin: -10px -10px 10px -10px;
    }
    @include rwd(small) {
      @include font(14, 17, 0);
    }
  }
}

.fancybox__slide > * {
  @include rwd(phone) {
    padding: 10px !important;
  }
}

.fancybox-container {
  z-index: 9999999999 !important;
}
