/*
	SETTINGS
*/
$global-full-width: 1366px;

// Colors
// stylelint-disable
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #171717 !default;

$theme: #5889C7;
$theme-light: #E2ECFC;
$theme-secondary: #94C03C;
$theme-secondary-light: #FEF6EC;
$theme-secondary-ultralight: #ececec;
$theme-hover: $theme-secondary;
$theme-secondary-hover: darken($theme-secondary, 10%);
$theme-gray: #858E96;
$theme-gray-lighter: #C3CBD3;
$theme-dark: #141516;
$theme-bg: #F4F8FB;
$font-family-redesign: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

// redesign
$redesign-event-box-bg: #F5F9FF;
$redesign-child-av-bg: #ECF2FF;
$redesign-parent-av-bg: #E9F3F3;
$redesign-green-color: #298A87;
$redesign-nav-bg: #E4EBF0;

// stylelint-disable
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #141516 !default;

$text: #141516 !default;
$primary: $theme !default;
$primary-dark: #141D2D !default;
$primary-hover: #7ba5ff;
$primary-light: #7ba5ff;
$secondary: $gray-600 !default;
$secondary-hover: darken($secondary, 10%);
$success: #94C03C !default;
$info: $theme !default;
$warning: #FCCC34 !default;
$danger: #E56161 !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$light-primary: #F1F5FF !default;

// statusy
$status_present: $success;
$status_absent: $danger;
$status_reported: darken($warning, 5);
$status_empty: $secondary;

$calendar-day-off: #f6f6f6;
$calendar-today: $primary;


$link-color: $primary !default;
$link-decoration: none !default;
$link-hover-color: $primary-hover !default;
$link-hover-decoration: none !default;

// kolory grup
$guardian-color: #8acc5a;
$children-color: #FCCC34;
$worker-color: $theme;
$default-color: #8acc5a;

// payments
$payment-plus: $success;
$payment-minus: $danger;
$payment-neutral: $text;

// scheduler
$schedule-border-color: #f1f1f1;

// Typography
$body-font-color: $text;
$global-weight-normal: 400;
$global-weight-bold: 700;
$global-lineheight: 1.5;

// reboot.scss
// stylelint-disable value-keyword-case
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;
$global-font-size: 16px;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;
$dt-font-weight: $font-weight-bold !default;

$body-bg: #F5F7FA !default;
$body-color: $text !default;

$spacer: 16px !default;

$headings-margin-bottom: $spacer !default;
$paragraph-margin-bottom: $spacer !default;

$enable-hover-media-query: false !default;

$table-cell-padding: .75rem !default;
$text-muted: $gray-600 !default;

// foundation
$rem-base: 16px !default;

$breakpoints: (
    small: 450px,
    phone: 640px,
    medium: 768px,
    tablet: 1024px,
    large: 1200px,
    xlarge: 1440px,
    xxlarge: 1660px,
) !default;

$sidebar-mobile-width: 300px;
$sidebar-right-width: 300px;
$sidebar-left-width: 92px;
$sidebar-width: 392px;
$dock-height: 60px;
$dock-item-height: 60px;
$desktop-web-header-height: 51px;
$mobile-web-header-height: 40px;
$mobile-web-dock-height: 60px;
$icon-selector: '.fal, .fas, .fa';
