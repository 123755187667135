@import '~quill/dist/quill.snow.css';

#quill-editor {
  height: 250px;

  .ql-editor {
    outline: none !important;
  }
}

.quill-wrapper {
  @include on-event() {
    .ql-toolbar, .ql-container, .quill-border-wrapper {
      border-color: #cfd2d5;
    }
  }

  .ql-toolbar, .ql-container, .quill-border-wrapper {
    @include transition(border-color)
  }

  color: #141D2D !important;

  .quill-border-wrapper {
    border: 1px solid $gray-200;
    border-radius: 5px;
  }

  .ql-toolbar {
    border: 1px solid $gray-200;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 4px;
  }

  .ql-container {
    border: none;
    margin-bottom: 20px;
    @extend .text--dark;
    font-size: 14px;
  }

  .ql-blank::before {
    font-style: normal !important;
    color: #495057;
    font-size: 0.875rem;
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: 'Tekst';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: 'Nagłówek 1';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    content: 'Nagłówek 2';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    content: 'Nagłówek 3';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    content: 'Nagłówek 4';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    content: 'Nagłówek 5';
  }

  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    content: 'Nagłówek 6';
  }

  .ql-color-picker {
    .ql-picker-options {
      width: 173px;
      @media screen and (min-width: 422px) {
        transform: translateX(-124px);
      }

      @media screen and (min-width: 450px) and (max-width: 459px) {
        transform: translate(0);
      }

      @include rwd(phone, true) {
        transform: translateX(0);
      }
    }
  }
}

/** Styles for quill content */
.quill-wrapper, .quill-styles {
  .ql-snow .ql-editor, .quill-styles-content {
    h1, h2, h3 {
      margin-bottom: 10px;
      font-weight: 700 !important;
    }

    h1 {
      @include font(20, 24, 0);
    }

    h2 {
      @include font(18, 20, 0);
    }

    h3 {
      @include font(16, 18, 0);
    }

    a {
      @apply text-link
    }
  }

  .ql-editor, .quill-styles-content {
    p, ol, ul {
      margin-bottom: 16px;
    }

    *:last-child {
      margin-bottom: 0;
    }
  }
}

.ql-toolbar {
  .ql-formats {
    .ql-resblock {
      background: #006f9f;
      color: #FFF;
      width: auto;
      border-radius: 8px;
      font-size: 12px;
      &:hover{
        color: #FFF;
        background: #1793c7;
      }
    }

    .ql-resblock::before {
      content: '{Nr Uchwały}'
    }
  }
}


.resblock {
  background: #006f9f;
  color: #FFF;
  width: auto;
  border-radius: 8px;
  font-size: 12px;
  height: 24px;
  padding: 3px 5px;
  &:hover{
    color: #FFF;
    background: #1793c7;
  }
}



/** List styles */
.quill-styles-content ul li {
  padding-left: 1.5em;
}

.quill-styles-content ol > li,
.quill-styles-content ul > li {
  list-style-type: none;
}
.quill-styles-content ul > li::before {
  content: '\2022';
}

.quill-styles-content li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}

.quill-styles-content ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}
.quill-styles-content ol li:before {
  content: counter(list-0, decimal) '. ';
}

.quill-styles-content ol li.ql-indent-1 {
  counter-increment: list-1;
}
.quill-styles-content ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) '. ';
}
.quill-styles-content ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.quill-styles-content ol li.ql-indent-2 {
  counter-increment: list-2;
}
.quill-styles-content ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) '. ';
}
.quill-styles-content ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.quill-styles-content ol li.ql-indent-3 {
  counter-increment: list-3;
}
.quill-styles-content ol li.ql-indent-3:before {
  content: counter(list-3, decimal) '. ';
}
.quill-styles-content ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.quill-styles-content .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.quill-styles-content li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
.quill-styles-content .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
.quill-styles-content li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
.quill-styles-content .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}
.quill-styles-content li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}
.quill-styles-content .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
.quill-styles-content li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}
.quill-styles-content .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}
.quill-styles-content li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}
.quill-styles-content .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
.quill-styles-content li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}

