@for $i from 1 through 6 {
  h#{$i} {
    font-family: $font-family-redesign;
    font-weight: 700;
    @include font((24 - 2 * $i), (29 - 2 * $i), 0);
    color: $primary-dark;
  }
}

.page-component {
  & + h1, & + h2, & + h3, & + h4, & + h5, & + h6 {
    @include rwd(tablet) {
      @include font(15, 32, 0);
      font-weight: 500;
    }
    @include rwd(phone) {
      & ~ .page-component {
        margin-top: 5px !important;
      }
    }
  }
}

.heading {
  margin-top: 0;
  margin-bottom: 20px;

  @include rwd(tablet) {
    margin-top: auto;
    margin-bottom: auto;
  }

  &-with-element {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .button__icon {
      #{$icon-selector} {
        font-size: 14px !important;

        @include rwd(tablet) {
          font-size: 20px !important;
        }
      }
    }

    .dots-icon {
      #{$icon-selector} {
        font-size: 20px !important;

        @include rwd(tablet) {
          font-size: 32px !important;
        }
      }
    }

    @include rwd(tablet) {
      margin-bottom: auto;
      position: absolute;
      justify-content: flex-end;
      align-items: flex-end;

      .heading--text {
        display: none;
      }

      .button__icon {
        width: 54px !important;
        height: 54px !important;
      }

      .button__text {
        display: none;
      }
    }

    @for $i from 1 through 6 {
      h#{$i} {
        margin-bottom: 0;
        @include rwd(small) {
          width: 100%;
        }
      }
    }

    &--left {
      justify-content: flex-start;
      position: relative;

      &:not(.heading-with-element--force-standard) {
        @include rwd(tablet) {
          justify-content: center;
        }
      }

      & > :nth-child(2), & > :nth-child(3) {
        margin-left: 8px;
        flex-shrink: 0;
        @include rwd(tablet) {
          margin-left: unset;
        }
      }
    }

    &--force-standard {
      @include rwd(tablet) {
        & > :nth-child(2) {
          position: relative;
          right: auto;

          .element-title {
            display: none;
          }

          #{$icon-selector} {
            font-size: 16px;
          }
        }
      }
    }

    a, a:not([href]) {
      color: $primary;
      @include on-event {
        color: $theme-secondary;
      }
    }

    //#{$icon-selector} {
    //  margin-left: 6px;
    //}
  }

  &--only-mobile {
    @include rwd(tablet, true) {
      display: none;
    }
  }
}
