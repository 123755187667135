.mobile-dock {
  $block: &;
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  @include flex(flex-end, stretch, false);
  padding: 0 0 var(--saib, 0) 0;
  box-shadow: 0 0 20px rgba(229,233,235, 0.8);

  @include rwd(tablet, true) {
    display: none !important;
  }

  &__item {
    height: $dock-item-height;
    flex: 1;
    width: 20%;
    text-align: center;
    cursor: pointer;
    @include flex(center, center, true);

    &.active {
      #{$block}__item-icon, #{$block}__item-label {
        color: $theme;
      }
    }
  }

  &__item-helper {
    width: 100%;
    height: $dock-item-height;
    @include flex(center, center, true);
  }

  &__item-layout {
    line-height: 0;
  }

  &__item-icon {
    color: $theme-dark;
    #{$icon-selector} {
      font-size: 16px;
    }
  }

  &__item-label {
    @extend %text-small;
    @extend .text--dark;
    margin-top: 4px;

    @include rwd(small) {
      @include font(11, 12, 0);
    }
  }

  &__item-count {
    width: 15px;
    height: 15px;
    background-color: $theme;
    color: #fff;
    @include flex(center, center, false);
    @include font(9, 9, 0);
    position: absolute;
    left: 50%;
    top: 6px;
    transform: translateX(-50%);
    margin-left: 10px;
    pointer-events: none;
    border-radius: 50%;
    font-weight: 500;
    text-align: center;

    &.hidden {
      display: none;
    }
  }
}
