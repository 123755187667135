.av {
  $block: &;
  text-align: center;
  position: relative;
  vertical-align: middle;
  font-weight: 700;
  @include flex(flex-start, center, false);
  font-family: $font-family-redesign;

  @at-root #{selector-unify(a, &)} {
    @include transition(background-color);
    border-radius: 4px;
    padding: 5px;

    @include on-event() {
      background-color: $theme-bg;
    }
  }

  &__layout {
    border-radius: 50%;
    @include flex(center, center, false);
    flex-shrink: 0;
    //overflow: hidden;
    position: relative;

    img {
      max-width: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }

    &--notification {
      &:before {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        top: 0;
        right: 0;
        background: $danger;
        border-radius: 50%;
        border: 2px solid #fff;
      }
    }
  }

  &__details {
    padding-left: 10px;
    text-align: left;
    color: $theme-dark;
  }

  &__info {
    color: $secondary;
    font-weight: 400;
  }

  &--invert {
    #{$block}__layout {
      order: 1;
    }

    #{$block}__details {
      order: 0;
      padding-left: 0;
      padding-right: 5px;
    }
  }

  &--without-layout {
    #{$block}__details {
      padding-left: 0;
    }
  }

  &--reversed {
    &-ph {
      @include rwd(phone) {
        flex-direction: column;

        #{$block}__details {
          padding-left: 0;
          text-align: center;
        }
      }
    }
  }

  // types
  &--guardian {
    #{$block}__layout {
      background-color: $guardian-color;
      color: #fff;
    }
  }

  &--child {
    #{$block}__layout {
      background-color: $children-color;
      color: #fff;
    }
  }

  &--redesign-child {
    #{$block}__layout {
      background-color: $redesign-child-av-bg;
      color: $primary;

      //  TODO: do poprawy
      .avatar__initials {
        color: $primary !important;
      }
    }
  }

  &--redesign-parent {
    #{$block}__layout {
      background-color: $redesign-parent-av-bg;
      color: $redesign-green-color;

      //  TODO: do poprawy
      .avatar__initials {
        color: $redesign-green-color !important;
      }
    }
  }

  &--worker {
    #{$block}__layout {
      background-color: $worker-color;
      color: #fff;
    }
  }

  &--default {
    #{$block}__layout {
      background-color: $default-color;
      color: #fff;
    }
  }

  // sizes
  &--tiny {
    #{$block}__layout {
      width: 25px;
      height: 25px;
      @include font(10, 10, 0);
    }

    #{$block}__name {
      @include font(10, 10, 0);
    }

    #{$block}__info {
      @include font(10, 10, 0);
    }
  }

  &--small {
    #{$block}__layout {
      width: 32px;
      height: 32px;
      @include font(12, 12, 0);
    }

    #{$block}__info {
      @include font(10, 10, 0);
    }
  }

  &--smedium {
    #{$block}__layout {
      width: 40px;
      height: 40px;
      @include font(14, 14, 0);
    }

    #{$block}__name {
      @include font(14, 14, 0);
    }

    #{$block}__info {
      @include font(10, 10, 0);
    }
  }

  &--medium {
    // TODO: medium avatar
    #{$block}__layout {
      width: 50px;
      height: 50px;
      @include font(16, 16, 0);
    }

    #{$block}__name {
      @include font(16, 16, 0);
    }

    #{$block}__info {
      @include font(13, 13, 0);
    }
  }

  &--large {
    // TODO: large avatar
    #{$block}__layout {
      width: 66px;
      height: 66px;
      @include font(20, 20, 0);
    }

    #{$block}__name {
      @include font(18, 18, 0);
    }

    #{$block}__info {
      @include font(13, 13, 0);
    }

    &-mobile {
      @include rwd(medium) {
        #{$block}__layout {
          width: 60px;
          height: 60px;
          @include font(18, 18, 0);
        }
        #{$block}__name {
          @include font(16, 16, 0);
        }
      }
      @include rwd(phone) {
        #{$block}__layout {
          width: 40px;
          height: 40px;
          @include font(14, 14, 0);
        }
      }
    }
  }

  @at-root #{selector-unify(&, a)} {
    #{$block}__name, #{$block}__info {
      @include transition(color);
    }

    @include on-event() {
      #{$block}__name, #{$block}__info {
        color: $theme;
      }
    }
  }
}


.av-list {
  margin-top: 10px;

  .av {
    margin-top: 10px;

    @at-root #{selector-unify(a, &)} {
      margin-top: 5px;
    }
  }
}
